
import { Dispatch } from "redux";
import { UIType, FetchType, AlertModal } from "../type";
import { Action } from '.'
import { ShowIssueTabInfo } from "../../interface/reducer-interface";
import { TAB_TYPE } from "../../utils/ui-constants";

export const fetchFilter = (buttonStatus: FetchType ) => {
    return (dispatch: Dispatch<Action>) => {

        dispatch({
            type: FetchType.FETCH_START,
            payload: buttonStatus
        });
          
        dispatch({
            type: FetchType.FETCH_END,
            payload: buttonStatus
        });
    }
}

export const showTab = (tabValue: TAB_TYPE, tabInfo?: ShowIssueTabInfo) => {
    console.log(tabValue, 'tabValueeee')
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: UIType.SHOW_TAB,
            payload: {
                tabValue,
                tabInfo
            }
        });
    }
}

export const removeTab = (tabValue: string) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: UIType.REMOVE_TAB,
            payload: tabValue
        });
    }
}


export const setCurrentTab = (tabValue: TAB_TYPE | string) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: UIType.SET_CURRENT_TAB,
            payload: tabValue
        });
    }
}

export const showAlertModal = (alertData: any) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: AlertModal.SHOW_ALERT_MODAL,
            payload: alertData
        })
    }
}

export const hideAlertModal = () => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: AlertModal.HIDE_ALERT_MODAL,
            payload: {},
        })
    }
}