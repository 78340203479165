import { FC } from 'react';
import Layout from './layout/main';
import AlertModal from './components/dialog-box/alert';
import Ribbon from '../src/components/ribbon/ribbon';

const App: FC = () => {

  return (
    <div className="App">
                {(process.env.REACT_APP_SERVER_NAME !== "prod" && process.env.REACT_APP_SERVER_NAME !== "") && <Ribbon />}

      <AlertModal />
      <Layout />
    </div>
  );
}

export default App;
