/**
 *  This is Tab section component  which contains all dashboard and another tab
 * 
 *  @author Aman Harde
 */

import React, { FC, useState } from 'react';
import { AppBar, Container, Grid, IconButton, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import TabPanel from '../../utils/tab-panel';
import Dashboard from '../tabs/dashboard';

import { AllIssuesTab, CreateIssueTab, IssueActivityLogTab, LabelTab, MilestoneTab, ViewIssueTab, UserStatusLogTab, UserAttendanceTab ,TeamAttendanceTab, UserActivityTab } from '../tabs';
import theme from '../../assets/theme/theme';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { removeTab, setCurrentTab } from '../../store/actions/ui-actions';
import { UIReducerInitalState } from '../../interface/reducer-interface';
import { TAB_VALUE } from '../../utils/ui-constants';
import CrudOperation from '../tabs/auth-user';
import { useHistory } from 'react-router-dom';
import TeamPerformancePage from '../teamPerformance/team-performance-table';

interface TabData {
    readonly lable: string,
    readonly icon: React.ReactElement,
    readonly component?: React.ReactElement,
    readonly type: "single" | "multiple"
}

interface TabType {
    readonly [tabValue: string]: TabData
}

export const TABS: TabType = {
    NEW_ISSUE_TAB: { lable: 'New Issue', icon: <i className="fas fa-bug size-24" />, component: <CreateIssueTab />, type: "single" },
    MILESTONE_TAB: { lable: 'Milestone', icon: <span className="material-icons">flag</span>, component: <MilestoneTab />, type: "single" },
    LABEL_TAB: { lable: 'Label', icon: <span className="material-icons">local_offer</span>, component: <LabelTab />, type: "single" },
    ALL_ISSUES_TAB: { lable: 'All Issues', icon: <span className="material-icons">view_list</span>, component: <AllIssuesTab />, type: "single" },
    CRUD_OPERATION_TAB: { lable: 'Auth Users', icon: <span className="material-icons">view_list</span>, component: <CrudOperation />, type: "single" },
    VIEW_ISSUE_INFO_TAB: { lable: 'Issue', icon: <i className="fas fa-bug size-24" />, type: "multiple" },
    ISSUE_ACTIVITY_LOG_TAB: { lable: 'Activity Log', icon: <i className="fas fa-bug size-24" />, type: "multiple" },
    USER_STATUS_LOG_TAB: { lable: 'User Status', icon: <span className="material-icons">person_search</span>, component: <UserStatusLogTab />, type: "single" },
    USER_ATTENDANCE_TAB: { lable: 'User Attendance', icon: <span className="material-icons">perm_contact_calendar</span>, component: <UserAttendanceTab />, type: "single" },
    TEAM_ATTENDANCE_TAB: { lable: 'Team Attendance', icon: <span className="material-icons">perm_contact_calendar</span>, component: <TeamAttendanceTab />, type: "single" },
    USER_ACTIVITY_TAB: {lable: 'User Activity', icon: <span className="material-icons">perm_contact_calendar</span>, component: <UserActivityTab />, type: "single"},
    TEAM_PERFORMANCE_TAB: {lable: 'Team Performance', icon: <span className="material-icons">perm_contact_calendar</span>, component: <TeamPerformancePage />, type: "single"}

}

const useStyles = makeStyles({
    root: {
        position: 'sticky',
        zIndex: theme.zIndex.drawer + 1,
        top: theme.spacing(6)
    },
    closeIcon: {
        width: theme.spacing(1) * 5
    },
    tabError: {
        marginTop: theme.spacing(2)
    }

});

const TabsSection: FC = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { tabs, currentTab } = useSelector((state: RootState) => state.uiReducer as UIReducerInitalState);

    const [state, setState] = useState({
        TAB_ErrorMessage: ''
    });
    const closeCurrentTab = (tabValue: string) => (event: any) => {
        event.stopPropagation();
        if (currentTab === tabValue) {
            let tabIndex: number = Object.keys(tabs).indexOf(tabValue.split(":")[0]) - 1;
            if (tabIndex === -1) {
                dispatch(setCurrentTab('DASHBOARD_TAB'));
            }
            else {
                if (Object.keys(tabs)[tabIndex] === 'VIEW_ISSUE_INFO_TAB') {
                    dispatch(setCurrentTab(`${Object.keys(tabs)[tabIndex]}:${tabs[Object.keys(tabs)[tabIndex]][tabs[Object.keys(tabs)[tabIndex]].length - 1].issueNumber}`));
                } else {
                    dispatch(setCurrentTab(Object.keys(tabs)[tabIndex]));
                }
            }
        }

        dispatch(removeTab(tabValue));
        setState({
            ...state,
            TAB_ErrorMessage: ''
        });
    }

    const handleTabChange = (event: any, currentTab: string) => {
        dispatch(setCurrentTab(currentTab));
        if (currentTab !== TAB_VALUE.DASHBOARD_TAB){
            
        } //history.push("/workspace");
    };
   

    return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
            <AppBar position="static" id='tab-panel-header' className={classes.root}>
                <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                >
                    <Tab
                        icon={<span className="material-icons">dashboard</span>}
                        label='IssueBoard'
                        value={TAB_VALUE.DASHBOARD_TAB}
                    />
                    {
                        Object.keys(tabs).length !== 0 &&
                        Object.keys(tabs).map((tabValue, index) => (
                            TABS[tabValue] !== undefined && (
                                TABS[tabValue].type === 'single' ?
                                    <Tab
                                        key={index}
                                        icon={TABS[tabValue].icon}
                                        value={tabValue}
                                        label={
                                            <Grid container justifyContent='space-around'>
                                                {
                                                    state.TAB_ErrorMessage === ''
                                                        ? TABS[tabValue].lable
                                                        : 'Something went wrong!'
                                                }
                                                <IconButton edge='end' size='small' color='inherit' title='Close tab' onClick={closeCurrentTab(tabValue)}>
                                                    <span className="material-icons">close</span>
                                                </IconButton>

                                            </Grid>
                                        }
                                    />
                                    : tabs[tabValue].map((_tabs, _index) => (
                                        tabValue === TAB_VALUE.VIEW_ISSUE_INFO_TAB ?
                                            <Tab
                                                key={_index}
                                                icon={TABS[tabValue].icon}
                                                value={`${tabValue}:${_tabs?.issueNumber}`}
                                                label={
                                                    <Grid container justifyContent='space-around'>
                                                        {
                                                            state.TAB_ErrorMessage === ''
                                                                ? `${TABS[tabValue].lable}: ${_tabs?.issueNumber}`
                                                                : 'Something went wrong!'
                                                        }
                                                        <IconButton edge='end' size='small' color='inherit' title='Close tab' onClick={closeCurrentTab(`${tabValue}:${_tabs?.issueNumber}`)}>
                                                            <span className="material-icons">close</span>
                                                        </IconButton>

                                                    </Grid>
                                                }
                                            /> :
                                            <Tab
                                                key={_index}
                                                icon={TABS[tabValue].icon}
                                                value={`${tabValue}:${_tabs?.issueNumber}`}
                                                label={
                                                    <Grid container justifyContent='space-around'>
                                                        {
                                                            state.TAB_ErrorMessage === ''
                                                                ? `${TABS[tabValue].lable}: ${_tabs?.issueNumber}`
                                                                : 'Something went wrong!'
                                                        }
                                                        <IconButton edge='end' size='small' color='inherit' title='Close tab' onClick={closeCurrentTab(`${tabValue}:${_tabs?.issueNumber}`)}>
                                                            <span className="material-icons">close</span>
                                                        </IconButton>

                                                    </Grid>
                                                }
                                            />

                                    ))
                            )))

                    }
                </Tabs>

            </AppBar>
            <Container maxWidth='xl'>
               { <TabPanel value={currentTab.split(":")[0]} index={TAB_VALUE.DASHBOARD_TAB}>
                    <Dashboard />
                </TabPanel>}
                {
                    TABS[currentTab.split(":")[0]] !== undefined &&

                    <TabPanel value={currentTab.split(":")[0]} index={currentTab.split(":")[0]}>
                        {
                            state.TAB_ErrorMessage === '' ?
                                TABS[currentTab.split(":")[0]].type === 'single' ?
                                    TABS[currentTab.split(":")[0]].component
                                    :
                                    (currentTab.split(":")[0] === TAB_VALUE.VIEW_ISSUE_INFO_TAB &&
                                        <ViewIssueTab issueNumber={currentTab.split(":")[1]} />
                                        ||
                                        currentTab.split(":")[0] === TAB_VALUE.ISSUE_ACTIVITY_LOG_TAB &&
                                        <IssueActivityLogTab issueNumber={currentTab.split(":")[1]} />)

                                : <Container>
                                    <Grid container spacing={5} direction='column' alignContent='center' className={classes.tabError}>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                            <Typography>{`Something went wrong!`}</Typography>
                                        </Grid>
                                    </Grid>
                                </Container>
                        }
                    </TabPanel>
                }
            </Container>
        </Grid>
    )
}


export default TabsSection;