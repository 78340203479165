import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import theme from '../../assets/theme/theme';
import { ClickAwayListener, Grid } from '@material-ui/core';
import { rightDrawerClickAwayHandler } from '../../utils/clickAwayListenerHandler';
import { FC } from 'react';
import LabelEdit from './label-edit';
import LabelCreate from './label-create';
const DRAWER_WIDTH = 400;

const useStyles = makeStyles({
  root: {
    display: 'contents'
  },
  drawerOpen: {
    height: `630px`,
    position: "absolute",
    width: DRAWER_WIDTH,
    right: 0,
    top: 200,
    border: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    border: 'none',
  },
});

interface CreateLabelProps {
  readonly drawerButton: string,
  readonly _fetchLabels: (event: any) => void
  readonly closeDialog: (event: any) => void
}

interface EditLabelProps {
  readonly labelNumber: string,
  readonly drawerButton: string,
  readonly _fetchLabels: (event: any) => void
  readonly closeDialog: (event: any) => void
}

const CreateLabel: FC<CreateLabelProps> = ({ drawerButton, closeDialog, _fetchLabels}) => {
  const classes = useStyles();

  const onCloseButtonClick = (event:any) => {
    if (rightDrawerClickAwayHandler()) {
         closeDialog(event);
    }
  }

  return (
    <ClickAwayListener touchEvent={drawerButton ? "onTouchEnd" : false} mouseEvent={drawerButton ? "onMouseUp" : false} onClickAway={onCloseButtonClick}>
      <Drawer
        open={drawerButton === 'CREATE'} 
        variant="permanent"
        anchor='right'
        id='create-milestone-drawer'
        PaperProps={{
          variant: 'elevation',
          elevation: 12
        }}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerButton === 'CREATE',
            [classes.drawerClose]: !(drawerButton === 'CREATE'),
          }),
            }}>
          <Grid item id="drawer-content-wrapper">
            <Grid container spacing={3} id="details-wrapper" className={classes.root}>
              <LabelCreate _fetchLabels={_fetchLabels} />
            </Grid>
          </Grid>
          </Drawer>
   </ClickAwayListener>
  );
}

const EditLabel: FC<EditLabelProps> = ({ drawerButton, closeDialog, _fetchLabels, labelNumber}) => {
  const classes = useStyles();

  const onCloseButtonClick = (event: any) => {
    if (rightDrawerClickAwayHandler()) {
         closeDialog(event);
    }
  }
  return (
    <ClickAwayListener touchEvent={drawerButton ? "onTouchEnd" : false} mouseEvent={drawerButton ? "onMouseUp" : false} onClickAway={onCloseButtonClick}>
      <Drawer
        open={drawerButton === 'EDIT'} 
        variant="permanent"
        anchor='right'
        id='create-milestone-drawer'
        PaperProps={{
          variant: 'elevation',
          elevation: 12
        }}
        classes={{
          paper: clsx({
            [classes.drawerOpen]:  drawerButton === 'EDIT',
            [classes.drawerClose]: !(drawerButton === 'EDIT'),
          }),
            }}>
          <Grid item id="drawer-content-wrapper">
            <Grid container spacing={3} id="details-wrapper" className={classes.root}>
             <LabelEdit labelNumber={labelNumber} _fetchLabels={_fetchLabels} />
            </Grid>
          </Grid>
          </Drawer>
   </ClickAwayListener>
  );
}

export { CreateLabel, EditLabel }