import axios from 'axios';

const HOLIDAY_URL = '/api/holidays'


// create holiday
export const createHoliday = (markHolidayRequest) => {
    const options = {
        method: 'POST',
        url: `${HOLIDAY_URL}`,
        data: markHolidayRequest
    }
    console.log('api',options)
    return axios(options)
   
}


export const findByMonth = month => {
    const options = {
        method: "GET",
        url: `${HOLIDAY_URL}`,
        params: month
    };

    return axios(options);
}


export const removeHoliday = date => {
    const options = {
        method: 'DELETE',
        url: `${HOLIDAY_URL}/date`,
        params: date
    };
    return axios (options)
}