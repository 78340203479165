/**
 * 
 *  This is TabPanel compenent which return children of tabs
 *  @author Aman Harde
 * 
 * 
 */

import { Fragment } from 'react';


function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${index}-tabpanel`}
            style={{ width: 'inherit' }}
            {...other}
        >
            {value === index && (
                <Fragment>
                    {children}
                </Fragment>
            )}
        </div>
    );
}
export default TabPanel