/**
 *  This is List issues 
 *
 *  @author Aman Harde
 */

import { Chip, colors, Grid, List, ListItem, ListItemText, makeStyles, Paper, Typography } from "@material-ui/core";
import { FC } from "react";
import theme from "../../assets/theme/theme";
import { IssueInfo } from "../../interface/issue-inteface";
import { formatToFromNow } from "../../utils/date-formater";


interface IssuesListProps {
    data: IssueInfo[],
    onIssueClick: (info: IssueInfo, event: any) => void

}

const style = makeStyles({
    issueList: {
        '& #ribbon-OPEN': {
            borderLeft: `${theme.spacing(1)}px solid ${'#2263dd'}`
        },
        '& #ribbon-CLOSED': {
            borderLeft: `${theme.spacing(1)}px solid ${colors.pink[100]}`
        },
        '& #ribbon-NEW': {
            borderLeft: `${theme.spacing(1)}px solid ${colors.blue[400]}`
        },
        '& #ribbon-ON_HOLD': {
            borderLeft: `${theme.spacing(1)}px solid ${theme.palette.warning.main}`
        },
        '& #ribbon-RESOLVED': {
            borderLeft: `${theme.spacing(1)}px solid ${theme.palette.success.dark}`
        }
    }
})

const IssuesList: FC<IssuesListProps> = (props) => {

    const classes = style();

    const onIssueClick = (info: IssueInfo) => (event: any) => {
        props.onIssueClick(info, event);
    }

    return (

        <Grid id='issue-list-wrapper'>
            <List component={Paper} variant='outlined' disablePadding id='issue-list' className={classes.issueList}>
                {
                    props.data.map((issue, index) => (
                        <ListItem
                            key={index}
                            divider={index !== (props.data.length - 1)}
                            button
                            onClick={onIssueClick(issue)}
                            id={`ribbon-${issue.state}`}
                        >
                            <ListItemText id='issue-wrapper'>
                                <Grid container spacing={1} >
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Typography variant='subtitle1' id={`issue-${issue.issueNumber}-title`}><strong>{`#${issue.issueNumber}: `}</strong>{issue.title}</Typography>
                                    </Grid>

                                    <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                                        <Typography variant='subtitle2' id={`issue-${issue.issueNumber}-info`}>
                                            {`Created ${formatToFromNow(issue.createdDate)} by `}<strong>{`${issue.createdBy.username}`}</strong>
                                        </Typography>
                                    </Grid>

                                    {
                                        issue.assignees.length !== 0 &&
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Typography variant='subtitle2'><strong>{`Assignees:`}</strong></Typography>
                                            {
                                                issue.assignees.map((assignee, _assignee) => (
                                                    <Chip
                                                        key={_assignee}
                                                        variant="default"
                                                        label={assignee.username}
                                                        size="small"
                                                    />
                                                ))
                                            }
                                        </Grid>
                                    }
                                    {
                                        issue.labels.length !== 0 &&
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Typography variant='subtitle2'><strong>{`Labels:`}</strong></Typography>
                                            {
                                                issue.labels.map((label, _label) => (
                                                    <Chip
                                                        key={_label}
                                                        variant="default"
                                                        label={label.value}
                                                        size="small"
                                                    />
                                                ))
                                            }
                                        </Grid>
                                    }
                                    {
                                        issue.milestone !== null &&
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Typography variant='subtitle2'><strong>{`Milestone:`}</strong></Typography>
                                            <Chip
                                                variant="default"
                                                label={`${issue.milestone.codeName} - [ ${issue.milestone.projectRef?.value} ]`}
                                                size="small"
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </ListItemText>
                        </ListItem>
                    ))
                }
            </List>
        </Grid>
    )
}


export default IssuesList;