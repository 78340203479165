import React from 'react';

export default function Ribbon() {
    console.log(process.env.REACT_APP_SERVER_NAME,"fddddddd")
    return (
        <div className="ribbon">
            
            {process.env.REACT_APP_SERVER_NAME} 
        </div>
    )
}