const RESPONSE_TYPE = "code";
const GRANT_TYPE = "authorization_code";
const CLIENT_ID = "client";
const SCOPE = "openid";
const SECRET = "secret";
const REFRESH_TOKEN = "refresh_token";


// Alert Constants
const SUCCESS = 'SUCCESS';
const DANGER = 'DANGER';
const WARNING = 'WARNING';
const INFO = 'INFO';


// Attendance constants
const PRESENT = 'PRESENT';
const ABSENT = 'ABSENT';
const ONLEAVE = 'ONLEAVE';
const INTIME = "INTIME";
const OUTTIME = "OUTTIME";
const CANCELLEAVE = "CANCELLEAVE"


const ALERT = Object.freeze({ SUCCESS, DANGER, WARNING, INFO });

const ATTENDANCE = Object.freeze({ PRESENT, ABSENT, ONLEAVE, INTIME, OUTTIME, CANCELLEAVE });

export { ALERT , ATTENDANCE};


export const SECURIY_CONFIG = Object.freeze({
    RESPONSE_TYPE, GRANT_TYPE, CLIENT_ID, SCOPE, SECRET, REFRESH_TOKEN 
});

