import { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid } from "@material-ui/core";
import { changePasswordApi } from "../../apis/user-api";
import { showAlertModal, hideAlertModal } from "../../store/actions/ui-actions";
import { ALERT } from "../../utils/ui-constants";
import { connect } from "react-redux";

function ChangePassword({
  open,
  handleClose,
  username,
  showAlertModal,
  hideAlertModal,
}) {
  const [state, setState] = useState({
    formData: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    fieldError: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  const onInputChange = (field) => (event) => {
    setState({
      ...state,
      formData: {
        ...state.formData,
        [field]: event.target.value,
      },
    });
  };

  const onSubmit = () => {
    changePasswordApi(username, state.formData)
      .then(() => {
        handleClose();
        showAlertModal({
          heading: "Success",
          description: `Password changed successfully.`,
          actionButtons: [
            {
              title: "Close",
              callback: (event) => {
                hideAlertModal(event);
              },
            },
          ],
          variant: ALERT.SUCCESS,
        });
      })
      .catch((err) => {
        showAlertModal({
          heading: "Error",
          description: `${err?.response?.data?.message}`,
          actionButtons: [
            {
              title: "Close",
              callback: (event) => {
                hideAlertModal(event);
              },
            },
          ],
          variant: ALERT.DANGER,
        });
      });
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <TextField
              autoFocus
              margin="dense"
              variant="outlined"
              id="old_password"
              label="Old Password"
              type="password"
              fullWidth
              value={state.formData.oldPassword}
              onChange={onInputChange("oldPassword")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <TextField
              margin="dense"
              variant="outlined"
              id="new_password"
              label="New Password"
              type="password"
              value={state.formData.newPassword}
              onChange={onInputChange("newPassword")}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <TextField
              margin="dense"
              variant="outlined"
              id="confirm_password"
              label="Confirm Password"
              type="password"
              value={state.formData.confirmNewPassword}
              onChange={onInputChange("confirmNewPassword")}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          size="small"
        >
          Close
        </Button>
        <Button
          onClick={onSubmit}
          color="primary"
          variant="contained"
          size="small"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapDispatchToProps = {
  showAlertModal,
  hideAlertModal,
};

export default connect(null, mapDispatchToProps)(ChangePassword);
