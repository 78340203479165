
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { showTab } from "../../../src/store/actions/ui-actions";
import { formatToFromNow } from "../../../src/utils/date-formater";
import { userActivityApi } from '../../apis/user-api';




function UserActivityTable(props) {
    const [userActivityData, setUserActivityData] = useState([]);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchUserActivityData = async () => {
            try {
                const response = await userActivityApi();
                console.log("aqaq", response.data)
                if (response.status === 200) {
                    const sortedData = response.data.sort((a, b) => a.username.localeCompare(b.username));
                    setUserActivityData(sortedData);
                }
            } catch (error) {
                console.error('Error fetching user activity data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserActivityData();
    }, []);


    const onIssueClick = (info, event) => {
        dispatch(showTab('VIEW_ISSUE_INFO_TAB', { issueNumber: info }));
        console.log("xyz", info);
    }

    return (
        <>
            <TableContainer component={Paper} style={{ paddingTop: '15px' }}>
                <div style={{ maxHeight: '700px', overflowY: 'scroll' }}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#263238', position: 'sticky', top: 0, zIndex: 1 }}>
                            <TableRow>
                                <TableCell style={{ color: 'white', fontSize: 20, width: '12%' }}>User Name</TableCell>
                                <TableCell style={{ color: 'white', align: 'right', fontSize: 20, width: '12%' }}>Total Issues</TableCell>
                                <TableCell style={{ color: 'white', align: 'left', fontSize: 20 }}>Open Issues</TableCell>
                                <TableCell style={{ color: 'white', align: 'right', fontSize: 20 }}>Assigned Issues</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {userActivityData.length !== 0 ? (
                                userActivityData.map((info, index) => (
                                    <TableRow key={index}>
                                        <TableCell >{info.username}</TableCell>
                                        <TableCell>{info.totalCount}</TableCell>
                                        {/* <TableCell >{info.issues[0].issueNumber} :  <Button onClick={(issueNumber) => { handleOpenIssueClick(issueNumber) }} size="small">{info.issues[0].state}</Button> : {info.issues[0].title}</TableCell>
                                    <TableCell>{info.issues[0].issueNumber}: <Button size="small">{info.issues[0].state}</Button>  :{info.issues[0].title}</TableCell> */}
                                    <TableCell>
                                        {info.openIssues.filter(issue => issue.state === "OPEN").map(openIssue => (

                                            <div key={openIssue.issueNumber}>
                                                {openIssue.issueNumber}:
                                                <Button onClick={() => { onIssueClick(openIssue.issueNumber) }} size="small"
                                                    style={{
                                                        color: '#0d54ff', fontWeight: 'bold', textTransform: 'none'
                                                    }}>
                                                    Open
                                                </Button>
                                                : {openIssue.title}
                                                <br />

                                                <Typography variant="caption" color="textSecondary" style={{
                                                    fontSize: '12px',
                                                    fontStyle: 'normal',
                                                    color: '#666',
                                                }} >
                                                    Last Comment: {formatToFromNow(openIssue.latestComment)}
                                                </Typography>

                                                <Typography variant="caption" color='#666' style={{
                                                    fontSize: '12px',
                                                    fontStyle: 'normal',
                                                    color: '#666',
                                                }}>
                                                    open since: {formatToFromNow(openIssue.openDate)}
                                                </Typography>


                                            </div>
                                        ))}
                                    </TableCell>

                                    <TableCell>

                                        {info.otherIssues.filter(issue => ["ON_HOLD", "RESOLVED", "NEW"].includes(issue.state)).map(onHoldIssue => (

                                            <div key={onHoldIssue.issueNumber}>
                                                {onHoldIssue.issueNumber}:
                                                <Button onClick={() => { onIssueClick(onHoldIssue.issueNumber) }} size="small"
                                                    style={{ color: onHoldIssue.state === 'RESOLVED' ? 'green' : onHoldIssue.state === 'ON_HOLD' ? '#ff9800' : onHoldIssue.state === 'NEW' ? '#42a5f5' : 'inherit', fontWeight: 'bold', textTransform: 'none' }}>
                                                    {onHoldIssue.state === 'RESOLVED' ? 'Resolved' : onHoldIssue.state === 'ON_HOLD' ? 'On Hold' : 'New'}
                                                </Button>
                                                : {onHoldIssue.title}
                                                <br />
                                                <span
                                                    style={{
                                                        fontSize: '12px',
                                                        fontStyle: 'normal',
                                                        color: '#666',
                                                    }}>

                                                    {onHoldIssue.state === "NEW" ?
                                                        `Created: ${formatToFromNow(onHoldIssue.createdDate)}` :
                                                        `Last Comment: ${formatToFromNow(onHoldIssue.latestComment)}`}
                                                    <br />
                                                </span>

                                            </div>
                                        ))}
                                         </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        <Typography variant="h5">Data Not Available</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </div>
            </TableContainer>
        </>
    );
}

export default UserActivityTable;