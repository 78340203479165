import React from "react";
import { connect } from "react-redux";
import { withStyles, Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress } from "@material-ui/core";
import { showAlertModal, hideAlertModal } from "../../../store/actions/ui-actions";
import theme from "../../../assets/theme/theme";
import { checkFieldErrors } from "../../../utils/field-error-checker";
import { resetPassword } from "../../../apis/user-api";
import { ALERT } from "../../../utils/ui-constants";

const style = {
  root: {
    display: "flex",
  },
  formHead: {
    backgroundColor: theme.palette.primary.main,
    height: 50,
  },
};

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        password: "",
        confirmPassword: "",
      },
      fieldError: {
        password: "",
        confirmPassword: ""
      },
      isLoading: false
    };
  }

  validateFields = () => {

    let fieldError = checkFieldErrors(
      this.state.fieldError,
      this.state.formData,
      this.state.formData.confirmPassword
    );

    this.setState({
      ...this.state,
      fieldError: fieldError.errors,
    });

    return fieldError.haError;
  };

  onChange = (event) => {
    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        password: event.target.value,
      },
      fieldError: {
        ...this.state.fieldError,
        password: ''
      }
    });
  };

  onChangeConfirm = (event) => {
    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        confirmPassword: event.target.value,
      },
      fieldError: {
        ...this.state.fieldError,
        confirmPassword: ''
      }
    });
  };

  onSaveClick = async event => {
    const { formData } = this.state;
    if (await !this.validateFields()) {
      this.setState({
        ...this.state,
        isLoading: true,
      });
      resetPassword(this.props.userName, formData.password)
        .then((response) => {
          this.onCancelClick();
          this.props.showAlertModal({ heading: 'Success', description: `Password reset successfully!`, actionButtons: [{ title: 'Close', callback: event => this.props.hideAlertModal() }], variant: ALERT.SUCCESS });
        }).catch(error => {
          this.setState({
            ...this.state,
            isLoading: false,
          });
        })
    }
  };

  onCancelClick = (event) => {
    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        password: "",
        confirmPassword: "",
      },
      isLoading: false,
      fieldError: {
        ...this.state.fieldError,
        password: '',
        confirmPassword: ''
      },
    });
    this.props.handleClose();
  };

  render() {
    const { buttonValue } = this.props;
    const { isLoading, formData, fieldError } = this.state;
    return (
      <div>
        <Dialog
          open={buttonValue === "RESET"}
          maxWidth="xs"
          id="reset-Password-box"
          disableBackdropClick
          disableEscapeKeyDown={isLoading}
          onClose={this.onCancelClick}
        >
          <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <TextField
                  variant="outlined"
                  id="password"
                  label="Password"
                  type="password"
                  size="small"
                  fullWidth
                  value={formData.password}
                  onChange={this.onChange}
                  error={fieldError.password !== ''}
                  helperText={fieldError.password}
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={formData.confirmPassword}
                  onChange={this.onChangeConfirm}
                  error={fieldError.confirmPassword !== ''}
                  helperText={fieldError.confirmPassword}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions id="add-user-action">
            <Button
              variant="contained"
              size="small"
              color="primary"
              id="edit-user-cancel"
              onClick={this.onCancelClick}
              disabled={isLoading}
            >
              Close
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              id="edit-user-save"
              onClick={this.onSaveClick}
              disabled={isLoading}
              endIcon={
                isLoading && (
                  <CircularProgress
                    color={isLoading ? "primary" : "inherit"}
                    size={20}
                  />
                )
              }
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = {
  showAlertModal,
  hideAlertModal,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(style)(ChangePassword));
