import { UIType, FetchType, AlertModal } from "../type";
import { Action } from "../actions";
import { TabsInfo, UIReducerInitalState } from "../../interface/reducer-interface";
import { TABS } from "../../view/workspace/tab-section";
const initialState: UIReducerInitalState = {
    tabs: {} as TabsInfo,
    currentTab: "DASHBOARD_TAB",
    fetchStatus: "FETCH_END" as FetchType,
    isShowAlertModal: false,
    alertModalData: ''
};

const uiReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case UIType.SHOW_TAB:

            if (action.payload.tabInfo === undefined) {
                return {
                    ...state,
                    tabs: {
                        ...state.tabs,
                        [action.payload.tabValue]: action.payload.tabValue
                    },
                    currentTab: action.payload.tabValue
                };
            } else {
                if (state.tabs[action.payload.tabValue] === undefined) {
                    return {
                        ...state,
                        tabs: {
                            ...state.tabs,
                            [action.payload.tabValue]: [action.payload.tabInfo]
                        },
                        currentTab: `${action.payload.tabValue}:${action.payload.tabInfo?.issueNumber}`
                    };
                } else {
                    if (state.tabs[action.payload.tabValue].some(t => t.issueNumber === action.payload.tabInfo?.issueNumber)) {
                        return {
                            ...state,
                            tabs: {
                                ...state.tabs,
                            },
                            currentTab: `${action.payload.tabValue}:${action.payload.tabInfo?.issueNumber}`
                        };
                    } else {
                        return {
                            ...state,
                            tabs: {
                                ...state.tabs,
                                [action.payload.tabValue]: state.tabs[action.payload.tabValue].concat(action.payload.tabInfo)
                            },
                            currentTab: `${action.payload.tabValue}:${action.payload.tabInfo?.issueNumber}`
                        };
                    }
                }
            }

        case UIType.REMOVE_TAB:

            if (TABS[action.payload.split(":")[0]].type === "single") {
                delete state.tabs[action.payload.split(":")[0]]
            } else {
                state.tabs[action.payload.split(":")[0]].splice(state.tabs[action.payload.split(":")[0]].findIndex((a) => a.issueNumber === action.payload.split(":")[1]), 1);
                if (state.tabs[action.payload.split(":")[0]].length === 0) {
                    delete state.tabs[action.payload.split(":")[0]];
                }
            }

            return {
                ...state,
                tabs: {
                    ...state.tabs
                }
            };
        case UIType.SET_CURRENT_TAB:
            return {
                ...state,
                currentTab: action.payload
            };
        case FetchType.FETCH_START:
            return {
                ...state,
                fetchStatus: action.payload
            };
        case FetchType.FETCH_END:
            return {
                ...state,
                fetchStatus: action.payload
            };   
        case AlertModal.SHOW_ALERT_MODAL:
            return {
                ...state,
                isShowAlertModal: true,
                alertModalData: action.payload
            };

        case AlertModal.HIDE_ALERT_MODAL:
            return {
                ...state,
                isShowAlertModal: false
            };
                       
        default:
            return state
    }
}

export default uiReducer;