import React from "react";
//import { TableCell, TableBody, Table,  TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import  UserActivityTable from "../useractivity/user-activity-table";


function UserActivity () {

return(
    
<div>
    <UserActivityTable />
</div>

)


}
export default UserActivity;