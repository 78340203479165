import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import theme from '../../assets/theme/theme';
import MilestoneCreateProps from './milestone-create'
import { ClickAwayListener, colors, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import { rightDrawerClickAwayHandler } from '../../utils/clickAwayListenerHandler';
import MilestoneEdit from './milestone-edit';
import { FC } from 'react';
const DRAWER_WIDTH = 400;

const useStyles = makeStyles({
  root: {
    display: 'contents'
  },
  drawerOpen: {
    height: `425px`,
    position: "absolute",
    width: DRAWER_WIDTH,
    right: 0,
    top: 272,
    border: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    border: 'none',
  },
  drawerHeadPrimary: {
    backgroundColor: theme.palette.primary.main
  },
  leftMargin: {
    marginLeft: 22
  },
  drawerHeadWrapper: {
    top: 0,
    position: 'sticky',
  },
  whiteText: {
    color: 'white'
  }
});

interface CreateMilestoneProps {
  readonly drawerButton: string,
  readonly _fetchMilestones: (event: any) => void
  readonly closeDialog: (event: any) => void
}

interface EditMilestoneProps {
  readonly milestoneInfo: { milestoneNumber: string, version: string },
  readonly drawerButton: string,
  readonly _fetchMilestones: (event: any) => void
  readonly closeDialog: (event: any) => void
}

interface MilestoneHeaderProps {
  readonly onCloseButtonClick: (event: any) => void,
  readonly heading: string
}

const CreateMilestone: FC<CreateMilestoneProps> = ({ drawerButton, closeDialog, _fetchMilestones }) => {
  const classes = useStyles();

  const onCloseButtonClick = (event: any) => {
    if (rightDrawerClickAwayHandler()) {
      closeDialog(event);
    }
  }

  return (
    <ClickAwayListener touchEvent={drawerButton ? "onTouchEnd" : false} mouseEvent={drawerButton ? "onClick" : false} onClickAway={onCloseButtonClick}>
      <Drawer
        open={drawerButton === 'CREATE'}
        variant="permanent"
        anchor='right'
        id='create-milestone-drawer'
        PaperProps={{
          variant: 'elevation',
          elevation: 12
        }}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerButton === 'CREATE',
            [classes.drawerClose]: !(drawerButton === 'CREATE'),
          }),
        }}>
        <Grid item id="drawer-content-wrapper">
          <Grid container spacing={3} id="details-wrapper" className={classes.root}>
            <Grid className={classes.drawerHeadWrapper} id="head-wrapper">
              <Grid container spacing={3} className={classes.root}>
                <MilestoneDrawerHead
                  onCloseButtonClick={onCloseButtonClick}
                  heading={'Create Milestone'}
                />
              </Grid>
              <Divider variant="fullWidth" />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <MilestoneCreateProps _fetchMilestones={_fetchMilestones} closeDialog={closeDialog} />
            </Grid>

          </Grid>
        </Grid>
      </Drawer>
    </ClickAwayListener>
  );
}

const EditMilestone: FC<EditMilestoneProps> = ({ drawerButton, closeDialog, _fetchMilestones, milestoneInfo }) => {
  const classes = useStyles();

  const onCloseButtonClick = (event: any) => {
    if (rightDrawerClickAwayHandler()) {
      closeDialog(event);
    }
  }
  return (
    <ClickAwayListener touchEvent={drawerButton ? "onTouchEnd" : false} mouseEvent={drawerButton ? "onClick" : false} onClickAway={onCloseButtonClick}>
      <Drawer
        open={drawerButton === 'EDIT'}
        variant="permanent"
        anchor='right'
        id='create-milestone-drawer'
        PaperProps={{
          variant: 'elevation',
          elevation: 12
        }}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerButton === 'EDIT',
            [classes.drawerClose]: !(drawerButton === 'EDIT'),
          }),
        }}>
        <Grid item id="drawer-content-wrapper">
          <Grid container spacing={3} id="details-wrapper" className={classes.root}>
            <Grid className={classes.drawerHeadWrapper} id="head-wrapper">
              <Grid container spacing={3} className={classes.root}>
                <MilestoneDrawerHead
                  onCloseButtonClick={onCloseButtonClick}
                  heading={'Edit Milestone'}
                />
              </Grid>
              <Divider variant="fullWidth" />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <MilestoneEdit milestoneInfo={milestoneInfo} _fetchMilestones={_fetchMilestones} closeDialog={closeDialog} />
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </ClickAwayListener>
  );
}

const MilestoneDrawerHead: FC<MilestoneHeaderProps> = ({ onCloseButtonClick, heading }) => {
  const classes = useStyles();

  return (
    <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}
      className={classes.drawerHeadPrimary} alignItems='center' justify="space-between">
      <Grid item xl={11} lg={11} md={11} sm={11} xs={11} >
        <Typography className={classes.whiteText} variant='subtitle1'>
          {heading}
        </Typography>
      </Grid>
      {
        onCloseButtonClick !== undefined && <Grid item>
          <IconButton size='small' color='secondary' onClick={onCloseButtonClick}>
            <span className="material-icons">close</span>
          </IconButton>
        </Grid>
      }
    </Grid>
  )
}

export { CreateMilestone, EditMilestone }