export enum UserType {
    LOGIN = "LOGIN",
    LOGOUT = "LOGOUT"
}

export enum UIType {
    SHOW_TAB = "SHOW_TAB",
    REMOVE_TAB = "REMOVE_TAB",
    SET_CURRENT_TAB = "SET_CURRENT_TAB",
}

export enum IssueType {
    SET_CREATE_ISSUE_DATA = "SET_CREATE_ISSUE_DATA"
}

export enum FetchType {
    FETCH_START = "FETCH_START",
    FETCH_END = "FETCH_END",
}

export enum AlertModal {
    SHOW_ALERT_MODAL = "SHOW_ALERT_MODAL",
    HIDE_ALERT_MODAL = "HIDE_ALERT_MODAL"
}
