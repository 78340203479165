import axios from "axios";

const ROOT_URL = '/api/issues';

export const getSavedFilters = () => {
    const options = {
        method: "GET",
        url: `/api/authuser/filters`,
    };
    return axios(options);
}

export const deleteIcon = (index) => {
    const options = {
        method: "DELETE",
        url: `/api/authuser/filters/${index}`
    }
    return axios(options);
}

export const filterAllIssues = request => {
    const options = {
      method: "GET",
      url: `${ROOT_URL}`,
      params: request.milestone
        ? {
            ...request,
            milestone: request.milestone.split(" : [")[1].split(" ]")[0].trim(),
          }
        : request,
    };

    return axios(options);
}

//TODO: Remove after merging the changes if it is not in use
export const filterIssuesDashboard = request => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}/find/issue-board`,
        params: request.milestone ? { ...request, milestone: request.milestone.split(" : [")[1].split(" ]")[0] } : request
    };

    return axios(options);
}

// To save applied filters
export const saveFilterRequest = request => {

    const options = {
        method: "PUT",
        url: `/api/authuser/filters`,
        data: request,
    };

    return axios(options);
};

export const editCommentApi = (issueNumber, request) => {
    const options = {
        method: "PUT",
        url: `${ROOT_URL}/${issueNumber}/update-comment`,
        data: request
    };

    return axios(options);
}

export const createIssue = request => {
    const options = {
        method: "POST",
        url: `${ROOT_URL}`,
        data: request
    };

    return axios(options);
}

export const fetchIssue = issueNumber => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}/${issueNumber}`
    };

    return axios(options);
}

export const postIssueComment = (issueNumber, request) => {
    const options = {
        method: "PUT",
        url: `${ROOT_URL}/${issueNumber}/comment`,
        data: request
    };

    return axios(options);
}

export const uploadIssueFiles = (issueNumber, files) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i], files[i].name);
    }

    const options = {
        method: "PUT",
        url: `${ROOT_URL}/${issueNumber}/uploads/files`,
        data: formData,
    }

    return options;
}

export const getIssueFiles = (issueNumber, request) => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}/${issueNumber}/uploads/files`,
        params: request
    }

    return axios(options);
}

export const deleteIssueFile = (issueNumber, request) => {
    const options = {
        method: "DELETE",
        url: `${ROOT_URL}/${issueNumber}/uploads/files`,
        data: request
    }

    return axios(options);
}

export const editIssue = (issueNumber, request) => {
    const options = {
        method: "PUT",
        url: `${ROOT_URL}/${issueNumber}`,
        data: request
    };

    return axios(options);
}

export const editIssueAssignee = (issueNumber, request) => {
    const options = {
        method: "PUT",
        url: `${ROOT_URL}/${issueNumber}/assignees`,
        data: request
    };

    return axios(options);
}

export const removeIssueAssignee = (issueNumber, request) => {
    const options = {
        method: "DELETE",
        url: `${ROOT_URL}/${issueNumber}/assignees`,
        data: request
    };

    return axios(options);
}

export const editIssueMilestone = (issueNumber, request) => {
    const options = {
        method: "PUT",
        url: `${ROOT_URL}/${issueNumber}/milestone`,
        data: request
    };

    return axios(options);
}

export const editIssueLabel = (issueNumber, request) => {
    const options = {
        method: "PUT",
        url: `${ROOT_URL}/${issueNumber}/labels`,
        data: request
    };

    return axios(options);
}

export const removeIssueLabel = (issueNumber, request) => {
    const options = {
        method: "DELETE",
        url: `${ROOT_URL}/${issueNumber}/labels`,
        data: request
    };

    return axios(options);
}

export const removeAllIssueLabel = (issueNumber) => {
    const options = {
        method: "DELETE",
        url: `${ROOT_URL}/${issueNumber}/labels/all`
    };

    return axios(options);
}

export const fetchIssuesRef = (request) => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}/find/snippets`,
        params: request
    };

    return axios(options);
}

export const removeIssuesLinks = (issueNumber, request) => {
    const options = {
        method: "DELETE",
        url: `${ROOT_URL}/${issueNumber}/links`,
        data: request
    };

    return axios(options);
}

export const deleteIssueLinks = (issueNumber, request) => {
    const options = {
        method: "DELETE",
        url: `${ROOT_URL}/${issueNumber}/files/links`,
        data: request
    };

    return axios(options);
}

export const updateIssuesLinks = (issueNumber, request) => {
    const options = {
        method: "PUT",
        url: `${ROOT_URL}/${issueNumber}/links`,
        data: request
    };

    return axios(options);
}

export const getFileUrl = (issueNumber, fileName) => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}/${issueNumber}/uploads/image-url`,
        params: {
            fileName: fileName
        }
    }
    return axios(options);
};



export const getIssueLogInfo = (issueNumber, fileName) => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}/${issueNumber}/log-info`,
    }
    return axios(options);
};

export const findUserStatusLogInfo = (request) => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}/user-log-info`,
        params: request,
    }
    return axios(options);
};

export const copyIssue = issueNumber => {
    const options = {
        method: "POST",
        url: `${ROOT_URL}/copy-issue`,
        params: issueNumber
    };

    return axios(options);
}

export const  postCreateReleaseIssue  = milestoneNumber => {
    const options = {
        method: "POST",
         url: `${ROOT_URL}/release/${milestoneNumber}`,
         params:milestoneNumber
    };

    return axios(options);
}

export const findAllIssues = request => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}/all-issues`,
        params: request.milestone
             ? {
                ...request,
                milestone: request.milestone.split(" : [")[1].split(" ]")[0].trim(),
             }
            :request, 
     }
    return axios(options)

    };
export const SupportLink = (issueNumber, request) => {
    const options = {
    method: "PUT",
        url: `${ROOT_URL}/${issueNumber}/support-link`,
        data: request,
    };

    return axios(options);
}

