import { FC } from "react"


const ARCHIVE_FOLDER = Object.freeze(['zip', 'rar']);
const IMAGE = Object.freeze(['jpeg', 'jpg', 'png', 'gif']);
const VIDEO = Object.freeze(['mp4', 'mpeg4']);
const PDF = Object.freeze(['pdf']);
const WORD_DOCUMENT = Object.freeze(['doc', 'docx', 'txt']);
const EXCEL_DOCUMENT = Object.freeze(['xls', 'xlsx', 'ods']);
const WEB_DOCUMENT = Object.freeze(['htm', 'html', 'xml', 'svg']);
const POWER_POINT_DOCUMENT = Object.freeze(['ppt', 'pptx']);


interface IconSelectorProps {
    extention: string
}

const IconSelector: FC<IconSelectorProps> = (props) => {
    return (
        ARCHIVE_FOLDER.includes(props.extention) ?
            <i className='file-icon fas fa-file-archive' style={{ color: '#4b4b4b', fontSize: 'larger' }} />
            : IMAGE.includes(props.extention) ?
                <span className="material-icons" style={{ color: '#dd4b39' }}>image</span>
                : VIDEO.includes(props.extention) ?
                    <span className="material-icons" style={{ color: '#df413d' }}>movie</span>
                    : PDF.includes(props.extention) ?
                        <span className="material-icons" style={{ color: '#dd4b39' }}>picture_as_pdf</span>
                        : EXCEL_DOCUMENT.includes(props.extention) ?
                            <i className="fas fa-file-excel" style={{ color: '#22a062', fontSize: 'larger' }} />
                            : WORD_DOCUMENT.includes(props.extention) ?
                                <i className="file-icon fas fa-file-word" style={{ color: '#2082f2', fontSize: 'larger' }} />
                                : POWER_POINT_DOCUMENT.includes(props.extention) ?
                                    <i className="file-icon fas fa-file-powerpoint" style={{ color: '#ca4223', fontSize: 'larger' }} />
                                    : WEB_DOCUMENT.includes(props.extention) ?
                                        <i className="file-icon fas fa-file-code" style={{ color: '#4b4b4b', fontSize: 'larger' }} />
                                        : <span className="material-icons" style={{ color: '#4b4b4b' }} >description</span>


    )
}

export default IconSelector;