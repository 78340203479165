import { combineReducers } from "redux";
import userReducer from "./user-reducer";
import uiReducer from "./ui-reducer";
import issueReducer from "./issue-reducer";

const reducers = combineReducers({
    userReducer: userReducer,
    uiReducer: uiReducer,
    issueReducer: issueReducer
})

export default reducers;

export type RootState = ReturnType<typeof reducers>