import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import reducers from "./reducers";


const persistConfig = {
    key: 'geomitra-one',
    storage: storage,
    whitelist: ["uiReducer"], // which reducer want to store
    blacklist: ['userReducer'],
    version: 0,
};

const pReducer = persistReducer(persistConfig, reducers);


export const store = createStore(
    pReducer,
    applyMiddleware(thunk)
);

export const persistor = persistStore(store);