import axios from "axios";

const ROOT_URL = '/api/milestones';

export const filterMilestones = request => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}/find`,
        params: request
    };

    return axios(options);
}

export const fetchMileStones = request => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}`,
        params: request
    };

    return axios(options);
}

export const getMileStone = milestoneNumber => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}/${milestoneNumber}`
    };

    return axios(options);
}

export const createMileStone = request => {
    const options = {
        method: "POST",
        url: `${ROOT_URL}`,
        data: request
    };

    return axios(options);
}

export const editMileStone = (milestoneNumber, request) => {
    const options = {
        method: "PUT",
        url: `${ROOT_URL}/${milestoneNumber}`,
        data: request
    };

    return axios(options);
}

export const closeMilestoneApi = (milestoneNumber) => {
    const options = {
        method: "PUT",
        url: `${ROOT_URL}/${milestoneNumber}/close`
    };

    return axios(options);
}

export const deleteMileStone = (milestoneNumber) => {
    const options = {
        method: "DELETE",
        url: `${ROOT_URL}/${milestoneNumber}`
    };

    return axios(options);
}

export const fetchMileStonesByPattern = (request) => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}/find/search`,
        params: request
    };

    return axios(options);
}

export const fetchMileStonesByPatternAndStatus = (request) => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}/find/status`,
        params: request
    };

    return axios(options);
}



export const findNextVersionSuggestion = (projectRef, type) => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}/find/next-suggest`,
        params: {
            projectRef: projectRef,
            type: type
        }
    };

    return axios(options);
}

export const getMileStoneStatusCount = milestoneNumber => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}/issue-counts `
    };

    return axios(options);
}