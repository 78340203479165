import React from "react";
import {
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  Paper,
  TableBody,
  Grid,
  Typography,
  colors,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import theme from "../../assects/theme";
import moment from "moment";
import MarkTeamAttendance from "./mark-teamattendance";
import { bold } from "@uiw/react-md-editor";
import { stringify } from "querystring";
import { ATTENDANCE } from "../../utils/constants";

const style = {
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 590,
    width: "100%",
  },
  headerColor: {
    backgroundColor: "black",
    border: "1px solid #dee2e6",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  skeleton: {
    padding: theme.spacing(3),
  },
  NOEXTRA: {
    height: theme.spacing(1),
    width: theme.spacing(1),
    borderRadius: "50%",
    border: "1px solid",
    position: "relative",
    top: theme.spacing(1) / 2,
    marginLeft: theme.spacing(1) / 4,
    marginRight: theme.spacing(1) / 4,
    display: "inline-block",
    background: "white",
  },
  NOTAVAILABLE: {
    height: theme.spacing(1),
    width: theme.spacing(1),
    borderRadius: "50%",
    border: "1px solid",
    position: "relative",
    top: theme.spacing(1) / 2,
    marginLeft: theme.spacing(1) / 4,
    marginRight: theme.spacing(1) / 4,
    display: "inline-block",
    background: theme.palette.error.main,
  },
  Green: {
    backgroundColor: colors.green[500],
  },
  Red: {
    backgroundColor: colors.red[500],
  },
  Blue: {
    backgroundColor: colors.lightBlue[300],
  },
  Grey: {
    backgroundColor: colors.grey[400],
  },
  dateInfo: {
    lineHeight: 0,
  },
  border: {
    border: "1px solid #dee2e6",
    // padding: '5px',
  },
  boxes: {
    border: "1px solid #dee2e6",
    padding: "10px",
  },

  tableCellRoot: {
    display: "table-cell",
    padding: "10px",
    fontSize: "0.875rem",
    textAlign: "left",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.43,
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    letterSpacing: "0.01071em",
    verticalAlign: "inherit",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
};

class AttendanceTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonValue: "",
      consoleUserNumber: "",
      day: "",
      authUserName: "",
      authUserFullName: "",
      recordedBy: "",
      name: "",
    };
  }

  attendance = (attendanceResponse, day) => {
    const { classes, holidayData } = this.props;
    // const holidayDates = holidayData.holidays.map((holiday) => holiday.date);
    const holidayDates = holidayData
      ? holidayData.map((holiday) => holiday.date)
      : [];
    // console.log("Holiday Data", holidayData);

    for (var i = 0; i < attendanceResponse.length; i++) {
      if (
        attendanceResponse[i].attendanceDate ===
        moment(day).format("YYYY-MM-DD")
      ) {
        if (attendanceResponse[i].attendance === "PRESENT") {
          return classes.Green;
        } else if (attendanceResponse[i].attendance === "ABSENT") {
          return classes.Red;
        } else if (
          attendanceResponse[i].attendance === "ONLEAVE" &&
          holidayDates.includes(moment(day).format("YYYY-MM-DD"))
        ) {
          return classes.Grey;
        } else {
          return classes.Blue;
        }
      }
    }
    if (moment(day).format("ddd") === "Sun") {
      return classes.Grey;
    }
    if (holidayDates.includes(moment(day).format("YYYY-MM-DD"))) {
      return classes.Grey;
    }
  };

  noExtra = (attendanceResponse, day) => {
    const { classes } = this.props;
    for (var i = 0; i < attendanceResponse.length; i++) {
      if (
        attendanceResponse[i].attendanceDate ===
        moment(day).format("YYYY-MM-DD")
      ) {
        if (attendanceResponse[i].noExtra === true) {
          return classes.NOEXTRA;
        }
      }
    }
  };

  notAvailable = (attendanceResponse, day) => {
    const { classes } = this.props;
    for (var i = 0; i < attendanceResponse.length; i++) {
      if (
        attendanceResponse[i].attendanceDate ===
        moment(day).format("YYYY-MM-DD")
      ) {
        if (attendanceResponse[i].notAvailable === true) {
          return classes.NOTAVAILABLE;
        }
      }
    }
  };

  markAttendance = (day, data, recordedBy, userName, authUserName, name) => (
    event
  ) => {
    if (moment(day).format("ddd") === "Sun") {
      this.setState({
        buttonValue: "",
        userName: "",
        day: "",
        authUserName: "",
        authUserFullName: "",
        recordedBy: "",
        name: "",
      });
    } else {
      this.setState({
        buttonValue: "Attendance",
        day: day,
        authUserName: data,
        authUserFullName: recordedBy,
        recordedBy: authUserName,
      });
    }
  };

  handleCloseMenu = (event) => {
    this.setState({
      buttonValue: "",
    });
  };

  getInTime = (attendanceResponse, day) => {
    const { classes, holidayData } = this.props;
    const holidayDates = holidayData
      ? holidayData.map((holiday) => holiday.date)
      : [];
    const attendanceData = attendanceResponse.find(
      (item) => item.attendanceDate === moment(day).format("YYYY-MM-DD")
    );
    
    if (attendanceData?.attendance === ATTENDANCE.PRESENT) {
      if (attendanceData && attendanceData.inTime !== null) {
        const formattedInTime = moment(attendanceData.inTime);
        if (formattedInTime.isValid()) {
          return formattedInTime.format("HH:mm");
        } else {
          return "--";
        }
      } else if (attendanceData && attendanceData.inTime === null) {
        return "--";
      }
      return "";
    }
  };
  getOutTime = (attendanceResponse, day) => {
    const attendanceData = attendanceResponse.find(
      (item) => item.attendanceDate === moment(day).format("YYYY-MM-DD")
    );
    if (attendanceData?.attendance === ATTENDANCE.PRESENT) {
      if (attendanceData && attendanceData.outTime !== null) {
        const formattedoutTime = moment(attendanceData.outTime);
        if (formattedoutTime.isValid()) {
          return formattedoutTime.format("HH:mm");
        } else {
          return "--";
        }
      } else if (attendanceData && attendanceData.outTime === null) {
        return "--";
      }
    }
  };
  render() {
    const {
      classes,
      days,
      data,
      fetchAttendance,
      name,
      holidayData,
    } = this.props;
    let visibleIndex = 0;

    return (
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label="sticky table"
            size={"medium"}
            className="table table-bordered"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  className={`${classes.headerColor} ${classes.stickyHeader}`}
                >
                  #
                </TableCell>
                <TableCell
                  align="left"
                  className={`${classes.headerColor} ${classes.stickyHeader}`}
                >
                  Name
                </TableCell>
                {days.map((day, index) => (
                  <TableCell
                    align="center"
                    className={`${classes.headerColor} ${classes.tableCellRoot} `}
                    key={index}
                  >
                    {moment(day).format("DD ddd")}
                  </TableCell>
                ))}
                ,
              </TableRow>
            </TableHead>

            <TableBody>
              {data.length !== 0 ? (
                data.map((info, index) => {
                  if (
                    info.authorities[0] === "DEVELOPER" ||
                    info.authorities.includes("REPORTER")
                  ) {
                    visibleIndex++;
                    return (
                      <TableRow key={index}>
                        <TableCell className={classes.border} align="left">
                          {visibleIndex}
                        </TableCell>
                        <TableCell align="left">
                          {info?.authUserFullName}
                        </TableCell>
                        {days.map((day, index) => {
                          const attendanceClass = this.attendance(
                            info?.attendanceResponse,
                            day
                          );
                          const inTime = this.getInTime(
                            info?.attendanceResponse,
                            day
                          );
                          const outTime = this.getOutTime(
                            info?.attendanceResponse,
                            day
                          );

                          return (
                            <TableCell
                              key={index}
                              style={{ padding: "10px" }}
                              className={`${attendanceClass} ${classes.boxes}`}
                              onClick={this.markAttendance(
                                day,
                                info?.authUserName,
                                info?.authUserFullName,
                                info?.attendanceResponse.recordedBy
                              )}
                            >
                              <div>{<b>{inTime}</b>}</div>
                              <div>{<b>{outTime}</b>}</div>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  }
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={days.length + 3}>
                    <Typography variant="h4"> Data Not Available </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <MarkTeamAttendance
          buttonValue={this.state.buttonValue}
          selectedAttendanceDate={this.state.day}
          handleCloseItem={this.handleCloseMenu}
          userName={this.state.authUserName}
          fetchAttendance={(e) => this.props.fetchAttendance(e)}
          name={this.state.authUserFullName}
          data={data}
          holidayData={holidayData}
          handleClearHoliday={this.props.handleClearHoliday}
        />
      </Paper>
    );
  }
}

export default withStyles(style)(AttendanceTable);
