import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import MDEditor from "@uiw/react-md-editor";
import { AxiosResponse } from "axios";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { editCommentApi, postIssueComment } from "../../apis/issue-api";
import theme from "../../assets/theme/theme";
import { Comment } from "../../interface/common-interface";
import { RootState } from "../../store/reducers";
import { formatToFromNow } from "../../utils/date-formater";
import { checkFieldErrors } from "../../utils/field-error-checker";

const useStyles = makeStyles({
  formWrapper: {
    marginTop: 0,
    margin: "auto",
  },
  comment: {
    padding: theme.spacing(1),
  },
  skeleton: {
    padding: theme.spacing(3),
  },
  position: {
    position: "relative",
  },
});

interface CCommentHandlerProps {
  readonly disableComment?: boolean;
  readonly issueNumber: string;
  readonly comments: Comment[];
  readonly wait?: boolean;
  readonly openCommentBox?: boolean;
  readonly _fetchIssue: (event: any) => void;
  readonly onClose?: () => void;
  readonly  handleAddComment?: () => void;
}

const CommentHandler: FC<CCommentHandlerProps> = (props) => {
  console.log("shub",props);
  const classes = useStyles();

  const [commentInfo, setCommentInfo] = useState({
    text: "",
    wait: false,
  });

  const [errors, setError] = useState({
    text: "",
    onSubmit: "",
  });

  const [commentIndex, setCommentIndex] = useState(0);
  // const [commentBoxOpen, setCommentBoxOpen] = useState(true);
  const [isCommentBoxEnabled, setCommentBoxEnabled] = useState(false);

  const { username } = useSelector(
    (state: RootState) => state.userReducer.userInfo
  );

  const onInputChange = (event: any) => {
    setCommentInfo({
      ...commentInfo,
      text: event.target.value,
    });
    setError({
      ...errors,
      text: "",
      onSubmit: "",
    });
  };

  const editComment = (index: number, event: any) => {
    if (props.handleAddComment) {
      props.handleAddComment();
    }
  
    setCommentInfo({
      ...commentInfo,
      text: props.comments[props.comments.length - index - 1]["text"],
    });
    setCommentIndex(props.comments.length - index);
  };
  
  const validateFields = (): boolean => {
    let fieldError = checkFieldErrors(
      { text: errors.text },
      { text: commentInfo.text }
    );
    setError({
      ...errors,
      ...fieldError.errors,
    });

    return fieldError.haError;
  };

  const submitComment = (event: any) => {
    if (!validateFields()) {
      setCommentInfo({
        ...commentInfo,
        wait: true,
      });

      if (commentIndex === 0) {
        postIssueComment(props.issueNumber, { text: commentInfo.text })
          .then((res: AxiosResponse<any>) => {
            props._fetchIssue(event);
            setCommentInfo({
              ...commentInfo,
              text: "",
              wait: false,
            });
            setError({
              ...errors,
              text: "",
              onSubmit: "",
            });
          })
          .catch((err: any) => {
            setError({
              ...errors,
              onSubmit: "Error while adding comment. Please try again.",
            });
          });
      } else {
        editCommentApi(props.issueNumber, {
          key: commentIndex,
          text: commentInfo.text,
        })
          .then((event) => {
            props._fetchIssue(event);
            setCommentInfo({
              ...commentInfo,
              text: "",
              wait: false,
            });
            setError({
              ...errors,
              text: "",
              onSubmit: "",
            });
            setCommentIndex(0);
          })
          .catch((err: any) => {
            setError({
              ...errors,
              onSubmit: "Error while adding comment. Please try again.",
            });
          });
      }
    }
  };

  const onCloseCommentBox = () => {
    // setCommentBoxOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Grid
      container
      spacing={3}
      item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      className={classes.formWrapper}
    >
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        {props.openCommentBox && (
          <MDEditor
            value={commentInfo.text}
            onChangeCapture={onInputChange}
            preview={"edit"}
            toolbarHeight={40}
            minHeight={200}
            maxHeight={600}
            placeholder="Insert comment here"
            id="commet-box"
            style={
              errors.text !== ""
                ? { borderColor: theme.palette.error.main }
                : undefined
            }
          />
        )}

        <FormHelperText error={errors.text !== ""}>
          {errors.text}
        </FormHelperText>
      </Grid>

      {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <FormHelperText error={errors.onSubmit !== ''}>
          {errors.onSubmit}
        </FormHelperText>
      </Grid> */}

      <Grid
        container
        spacing={1}
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        direction="row-reverse"
        alignContent="flex-end"
      >
        {props.openCommentBox && (
          <Grid item>
            <Button
              variant="contained"
              size="small"
              color="primary"
              id="submit-comment-button"
              onClick={submitComment}
              disabled={commentInfo.wait || props.disableComment}
              endIcon={
                commentInfo.wait && (
                  <CircularProgress color="primary" size={20} />
                )
              }
            >
              {"Comment"}
            </Button>
            &nbsp;
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={onCloseCommentBox}
            >
              Close
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        {props.wait ? (
          <List dense disablePadding>
            {Array(3)
              .fill(3)
              .map((skeleton, index) => (
                <ListItem disableGutters dense key={index}>
                  <Skeleton
                    variant="rect"
                    width="90%"
                    height="80px"
                    animation="wave"
                  />
                </ListItem>
              ))}
          </List>
        ) : props.comments.length !== 0 ? (
          <List dense disablePadding>
            {props.comments
              .slice()
              .reverse()
              .map((comment, index) => (
                <ListItem key={index}>
                  <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    component={Paper}
                    elevation={4}
                    variant="outlined"
                    id="comment"
                    className={classes.comment}
                    style={{wordBreak: 'break-word'}}
                  >
                    <Grid container>
                      <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                        {
                          <Typography variant="subtitle2">
                            <strong>{comment.commentBy.username}</strong>
                          </Typography>
                        }
                        <Typography variant="caption">
                          {formatToFromNow(comment.dateTime)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        alignContent="flex-end"
                      >
                        {props.comments[props.comments.length - index - 1][
                          "commentBy"
                        ]["username"] === username && (
                          <IconButton
                            edge="end"
                            id="edit-comment-button"
                            onClick={(e: any) => editComment(index, e)}
                          >
                            <span className="material-icons">edit</span>
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>

                    <div id="comment-view">
                      <MDEditor.Markdown source={comment.text} />
                    </div>
                  </Grid>
                </ListItem>
              ))}
          </List>
        ) : (
          <Typography
            variant="subtitle1"
            align="center"
          >{`Comments not available`}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default CommentHandler;
