/**
 * This is view and update issue
 * @author Aman Harde
 * @since 1.0
 */

import {
    Button, Chip, colors, Container, Divider, FormHelperText, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Popover, TextField, Typography, Paper,
    TableCell, TableContainer, TableBody, TableRow, Table, TableHead
} from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import axios, { AxiosResponse } from "axios";
import { FC, Fragment, MutableRefObject, useEffect, useRef, useState } from "react";
import { getIssueLogInfo } from "../../apis/issue-api";
import theme from "../../assets/theme/theme";
import { formatToDefaultWithTime } from "../../utils/date-formater";
import { useDispatch } from "react-redux";
import ViewIssueDiff from '../issue/modal/view-diff';


const style = makeStyles({
    root: {
        display: 'contents'
    },
    milestoneContainer: {
        margin: 'auto'
    },
    milestoneTable: {
        display: 'block'
    },
    formWrapper: {
        marginTop: 0,
        marginRight: 0,
        margin: 'auto'
    },
    popover: {
        marginTop: theme.spacing(0.5),
    },
});

interface IssueActivityLogProps {
    readonly issueNumber: string
}

const ViewIssue: FC<IssueActivityLogProps> = (props) => {
    const classes = style();

    const [state, setState] = useState({
        logInfo: [] as any,
        diff: '""',
        wait: false,
        buttonValue: '' as string
    });


    const jsonStyle = {
        propertyStyle: { color: 'black', fontWeight: 'bold', fontSize: 16 },
        style: { color: 'black', fontSize: 16 }
    }

    useEffect(() => {
        fetchIssueActivityLogInfo(props.issueNumber);
    }, [props.issueNumber],
    );


    const fetchIssueActivityLogInfo = (issueNumber: string) => {
        getIssueLogInfo(issueNumber).then((res: AxiosResponse<any>) => {
            setState({
                ...state,
                logInfo: res.data,
                wait: false
            });
        }).catch((err: any) => {

        });
    }

    const handleClose = (event: any) => {
        setState({
            ...state,
            buttonValue: '',
        });
    }


    const onActionClick = (action: string, info: any) => (event: any) => {
        console.log('info', info)
        setState({
            ...state,
            buttonValue: action,
            diff: info.diff
        });
    }




    return (
        <Container id='milestone-wrapper' maxWidth='xl'>
            <Grid container spacing={3} item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.root}>
                <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item xl={10} lg={8} md={8} sm={6} xs={12}>
                        <Typography variant="h4"> {`Issue Activity Log Info -${props.issueNumber}`}</Typography>
                    </Grid>
                </Grid>
                <Fragment>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>User</TableCell>
                                        <TableCell>Time</TableCell>
                                        <TableCell>Issue State</TableCell>
                                        <TableCell>Activity Performed</TableCell>
                                        <TableCell align='center'>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        false ?
                                            Array(5).fill(5).map((skeleton, index) => (
                                                <TableRow key={index}>
                                                    <TableCell colSpan={7} align='center'>
                                                        <Skeleton
                                                            variant="rect"
                                                            width="95%" height='10%'
                                                            animation='wave'
                                                        // className={classes.skeleton}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            :
                                            state.logInfo.length !== 0 ?
                                                state.logInfo.map((info: any, index: string) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{info?.userName}</TableCell>
                                                        <TableCell> {formatToDefaultWithTime(info?.time)} </TableCell>
                                                        <TableCell>{info?.state}</TableCell>
                                                        <TableCell>{info?.activityInfo}</TableCell>
                                                        <TableCell align='center'>
                                                            <IconButton size='small' color='primary' title='View Change Log' onClick={onActionClick('view-diff', info)}>
                                                                <span className="material-icons">search</span>
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                                :
                                                <TableRow>
                                                    <TableCell colSpan={7} align="center">
                                                        <Typography variant="h5"> Data Not Available </Typography>
                                                    </TableCell>
                                                </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer >
                    </Grid>
                </Fragment>

            </Grid>
            <ViewIssueDiff
                buttonValue={state.buttonValue}
                logInfo={state.diff}
                handleClose={(event: any) => { handleClose(event) }}
                jsonStyle={jsonStyle}

            />
        </Container>
    )
}

export default ViewIssue;