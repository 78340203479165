import axios from "axios";

const ROOT_URL = "/api";

export const getLogin = (request) => {
  const options = {
    method: "POST",
    url: `${ROOT_URL}/authenticate`,
    data: request,
  };

  return axios(options);
};

export const getAuthUserInfo = (userName) => {
  const options = {
    method: "GET",
    url: `${ROOT_URL}/authuser/${userName}`,
  };

  return axios(options);
};

export const editAuthUser = (request, userName) => {
  const options = {
    method: "PUT",
    url: `${ROOT_URL}/authuser/${userName}`,
    data: request,
  };

  return axios(options);
};

export const getUsersByPattern = (request) => {
  const options = {
    method: "GET",
    url: `${ROOT_URL}/authuser`,
    params: request,
  };

  return axios(options);
};

export const filterData = (request) => {
  const options = {
    method: "GET",
    url: `${ROOT_URL}/authuser/find`,
    params: request,
  };
  return axios(options);
};

export const createAuthUser = (request) => {
  const options = {
    method: "POST",
    url: `${ROOT_URL}/authuser`,
    data: request,
  };

  return axios(options);
};

export const enableStatusApi = (userName) => {
  const options = {
    method: "PUT",
    url: `${ROOT_URL}/authuser/${userName}/status`,
  };

  return axios(options);
};

export const resetPassword = (userName, newPassword) => {
  const options = {
    method: "PUT",
    url: `${ROOT_URL}/authuser/${userName}/reset-password`,
    params: {
      newPassword: newPassword
    }
  };

  return axios(options);
};

export const changePasswordApi = (userName, request) => {
  const options = {
    method: "PUT",
    url: `${ROOT_URL}/authuser/${userName}/change-password`,
    data: request,
  };

  return axios(options);
};

export const userActivityApi = () => {
  const options = {
    method: "GET",
    url: `${ROOT_URL}/issues/user-activity`,
    
  };

  return axios(options);
};
