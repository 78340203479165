import MomentUtils from "@date-io/moment";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "antd";
import "antd/dist/reset.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  markAttendance,
  onLeaveAttendance,
  outTimeAttendance,
} from "../../apis/attendance-api";
import { hideAlertModal, showAlertModal } from "../../store/actions/ui-actions";
import { ALERT, ATTENDANCE } from "../../utils/constants";
import {
  dateFormatConverter3,
  localDateFormatConverter2,
} from "../../utils/dateFormates";
import {
  ValidateAttendance,
  ValidateReason,
} from "../../validations/console-validation";

const { RangePicker } = DatePicker;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  userWrapper: {
    marginRight: 0,
  },
  heading: {
    padding: "inherit",
  },
  subContent: {
    paddingLeft: "inherit",
  },
  datePicker: {
    padding: "inherit",
  },
  bold: { fontWeight: "bold" },
}));

function Userattendance(props) {
  console.log("shub", props.reason);

  const classes = useStyles();

  const [disableOutTime, setDisableOutTime] = useState(true);

  const [state, setState] = useState({
    selectedAttendanceMonth: new Date(),
    selectedAttendanceDate: new Date(),
    selectedDate: new Date(),

    markRequest: {
      attendanceDate: null,
      attendance: null,
      inTime: null,
      outTime: null,
    },
    fieldError: {
      attendance: "",
    },
  });

  const [markLeaveRequest, setMarkLeaveRequest] = useState({
    leaveReason: "",
    fromDate: "",
    toDate: "",
  });

  const [currentdate, setCurrentdate] = useState("");

  const [isOnLeaveSelected, setIsOnLeaveSelected] = useState(false);

  const { selectedAttendanceDate, attendanceData, userInfo } = props;

  const { markRequest, fieldError, SignupClose } = state;

  const [intime, setInTime] = useState();

  useEffect(() => {
    if (selectedAttendanceDate !== null) {
      isAttendanceMarked();
      checkDateIsSameOrAfter();
    }
  }, [selectedAttendanceDate]);

  useEffect(() => {
    var date = new Date().getDate(); //current date
    var month = new Date().getMonth() + 1; //current month
    var year = new Date().getFullYear(); //current year
    var hours = new Date().getHours(); //current hours
    var min = new Date().getMinutes(); //current minutes
    var sec = new Date().getSeconds(); //current seconds
    setCurrentdate(
      date + "/" + month + "/" + year + "    " + hours + ":" + min + ":" + sec
    );
  });

  useEffect(() => {
    setInTime(state.markRequest.inTime || false);
  }, [state.markRequest]);

  //on-leave attendance 'from-date to to-date'
  const [dates, setDates] = useState([]);

  const dateFormat = "DD/MM/YYYY";

  const handleOnChange = (values) => {
    console.log("value", values);

    const fromTime = values[0].$d;
    const toTime = values[1].$d;

    setDates(
      values.map((item) => {
        return moment(item).format("DD-MM-YYYY");
      })
    );

    setMarkLeaveRequest((prev) => ({
      ...prev,
      fromDate: fromTime,
      toDate: toTime,
    }));
  };

  const checkDateIsSameOrAfter = () => {
    if (!moment(selectedAttendanceDate).isSameOrAfter(new Date(), "day")) {
      onCancelClick();

      const attendanceRecord = getAttendanceRecordForDate(
        selectedAttendanceDate
      );

      if (attendanceRecord) {
        const inTime = attendanceRecord.inTime
          ? `In Time: ${formatTime(attendanceRecord.inTime)}`
          : "In Time: Not Marked";
        const outTime = attendanceRecord.outTime
          ? `Out Time: ${formatTime(attendanceRecord.outTime)}`
          : "Out Time: Not Marked";

          const text =  `You Cannot Mark Attendance for the Previous Day! \n ${inTime} \n ${outTime}`

        props.showAlertModal({
          heading: "Warning",
          description:  <div>
          {text.split("\n").map((line, key) => {
            if (key === 1 || key === 2) {
              return (
                <div key={key}>
                  <strong>{line}</strong>
                </div>
              );
            } else {
              return <div key={key}>{line}</div>;
            }
          })}
        </div>,
          actionButtons: [
            { title: "Close", callback: (event) => props.hideAlertModal() },
          ],
          variant: ALERT.WARNING,
        });
      } else {
        props.showAlertModal({
          heading: "Warning",
          description: "You Cannot Mark Attendance for the Previous Day!",
          actionButtons: [
            { title: "Close", callback: (event) => props.hideAlertModal() },
          ],
          variant: ALERT.WARNING,
        });
      }

      return true;
    }
  };

  const getAttendanceRecordForDate = (date) => {
    const formattedDate = localDateFormatConverter2(date);

    for (var key in attendanceData) {
      if (attendanceData[key].attendanceDate === formattedDate) {
        return attendanceData[key];
      }
    }

    return null;
  };

  const isAttendanceMarked = () => {
    for (var key in attendanceData) {
      if (
        attendanceData[key].attendanceDate ===
        localDateFormatConverter2(selectedAttendanceDate)
      ) {
        setState({
          ...state,
          markRequest: attendanceData[key],
        });
        return true;
      }
    }
    return false;
  };

  const _markAttendance = async (event) => {
    if (!(await hasErrorInForm())) {
      const _markRequest = {
        ...markRequest,

        attendanceDate: localDateFormatConverter2(selectedAttendanceDate),
      };

      markAttendance(_markRequest)
        .then((res) => {
          if (res.status == 200) {
            onCancelClick();

            props.showAlertModal({
              heading: "Success",
              description: `Your in time attendance marked Sucessfully!`,
              actionButtons: [
                {
                  title: "Close",
                  callback: (event) => {
                    props.hideAlertModal();
                    window.location.reload();
                  },
                },
              ],
              variant: ALERT.SUCCESS,
            });
            disableOutTime(false);
          } else {
            props.showAlertModal({
              heading: "Error",
              description: "Server Error! Please Try Again Later.",
              actionButtons: [
                {
                  title: "Close",
                  callback: (event) => {
                    props.hideAlertModal();
                    window.location.reload();
                  },
                },
              ],
              variant: ALERT.DANGER,
            });
          }
        })
        .catch((err) => {
          // props.showAlertModal({
          //   heading: "Error",
          //   description: "Server Error! Please Try Again Later.",
          //   actionButtons: [
          //     { title: "Close", callback: (event) => props.hideAlertModal() },
          //   ],
          //   variant: ALERT.DANGER,
          // });
        });
    }
  };

  const _outTime = async (event) => {
    if (!(await hasErrorInForm())) {
      const _markRequest = {
        ...markRequest,

        attendanceDate: localDateFormatConverter2(selectedAttendanceDate),
      };

      outTimeAttendance(_markRequest)
        .then((res) => {
          console.log("LM10", res.data.outTime);
          if (res.status == 200) {
            onCancelClick();

            props.showAlertModal({
              heading: "Success",
              description: `Your out time attendance marked Sucessfully!`,
              actionButtons: [
                {
                  title: "Close",
                  callback: (event) => {
                    props.hideAlertModal();
                    window.location.reload(); // Reload the page after marking out time
                  },
                },
              ],
              variant: ALERT.SUCCESS,
            });
          } else {
            props.showAlertModal({
              heading: "Error",
              description: "Server Error! Please Try Again Later.",
              actionButtons: [
                { title: "Close", callback: (event) => props.hideAlertModal() },
              ],
              variant: ALERT.DANGER,
            });
          }
        })
        .catch((err) => {
          // props.showAlertModal({
          //   heading: "Error",
          //   description: "Server Error! Please Try Again Later.",
          //   actionButtons: [
          //     { title: "Close", callback: (event) => props.hideAlertModal() },
          //   ],
          //   variant: ALERT.DANGER,
          // });
        });
    }
  };

  const _onLeaveAttendance = async (event) => {
    if (!(await hasErrorInForm())) {
      onLeaveAttendance(markLeaveRequest)
        .then((res) => {
          console.log("ucl", res);

          if (res.status == 200) {
            onCancelClick();
            props.showAlertModal({
              heading: "Success",
              description: `Your leave marked Sucessfully!`,
              actionButtons: [
                {
                  title: "Close",
                  callback: (event) => {
                    props.hideAlertModal();
                    window.location.reload();
                  },
                },
              ],
              variant: ALERT.SUCCESS,
            });
          } else {
            props.showAlertModal({
              heading: "Error",
                           description: "Leave already marked", description: `Leave already marked\n\nReason: ${markLeaveRequest.leaveReason}`,

              actionButtons: [
                {
                  title: "Close",
                  callback: (event) => {
                    props.hideAlertModal();
                    window.location.reload();
                  },
                },
              ],
              variant: ALERT.DANGER,
            });
          }
        })
        .catch((err) => {
          console.log("asd", err.response);
          props.showAlertModal({
            heading: "Error",
            description: "Please Enter Reason",
            actionButtons: [
              { title: "Close", callback: (event) => props.hideAlertModal() },
            ],
            variant: ALERT.DANGER,
          });
        });
    }
  };

  const hasErrorInForm = async () => {
    let hasError = false;
    let errorObj = {};
    for (let field in state.fieldError) {
      {
        var error = validateField(
          field,
          markRequest[field],
          markLeaveRequest[field]
        );
        errorObj[field] = error;
        if (error !== "") {
          hasError = true;
        }
      }
    }
    await setState({
      ...state,
      fieldError: errorObj,
    });
    return hasError;
  };

  const validateField = (field, value) => {
    switch (field) {
      case "leaveReason":
        return ValidateReason(value);

      case "attendance":
        return ValidateAttendance(value);

      default:
        return "";
    }
  };

  const onSaveClick = (event) => {
    if (state.markRequest.attendance === ATTENDANCE.PRESENT) {
      _markAttendance(event);
    }
    if (state.markRequest.outTime === true) {
      _outTime(event);
    } else if (state.markRequest.attendance === ATTENDANCE.ONLEAVE) {
      _onLeaveAttendance(event);
    }
  };

  const onAttendanceSelect = (event) => {
    setState({
      ...state,
      markRequest: {
        ...state.markRequest,
        attendance: event.target.value,
      },
      fieldError: {
        attendance: "",
      },
    });
    setIsOnLeaveSelected(event.target.value === ATTENDANCE.ONLEAVE);
  };

  const inTime = (event) => {
    let a = { currentdate };
    setInTime(event.target.checked);

    setState({
      ...state,
      markRequest: {
        ...state.markRequest,
        inTime: event.target.checked,
      },
    });
    console.log("in-time:", a);
  };

  const outTime = (event) => {
    setState({
      ...state,
      markRequest: {
        ...state.markRequest,
        [event.target.value]: event.target.checked,
      },
    });
  };

  const onHandleFrom = (event) => {
    setState({
      ...state,
      // markRequest: {
      //   ...state.markRequest,
      //   [event.target.value]: event.target.checked,
      // },
      markLeaveRequest: {
        ...markLeaveRequest,
        [event.target.value]: event.target.checked,
      },
    });
  };

  const onCancelClick = (event) => {
    props.closeModal();
    setState({
      ...state,
      markRequest: {
        attendanceDate: null,
        attendance: null,
        inTime: false,
      },
    });
  };

  const formatTime = (timeString) => {
    const formattedTime = moment(timeString, "YYYY-MM-DD HH:mm:ss").format(
      "h:mm A"
    );
    return formattedTime;
  };

  return (
    <Dialog
      open={selectedAttendanceDate !== null}
      maxWidth="sm"
      fullWidth={true}
      onClose={onCancelClick}
      disableBackdropClick={true}
      id="attendance-modal"
    >
      <DialogTitle id="attendance-modal-title">
        <Grid container spacing={1} alignItems="center">
          <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
            <AssignmentTurnedInIcon fontSize="large" />
          </Grid>
          <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
            <Typography variant="h6"> {"Mark Attendance"} </Typography>
            <Typography variant="subtitle2">
              Date: {dateFormatConverter3(selectedAttendanceDate)}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers id="attendance-modal-description">
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <FormControl error={fieldError.attendance !== ""}>
              <FormControlLabel
                id="attendance-in-time"
                label={
                  <div>
                    <label style={{ marginLeft: "8px" }}>Current Time</label>
                  </div>
                }
                value={Object.freeze(currentdate)}
                control={
                  <TextField
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={state.markRequest.outTime === true ? true : false}
                  />
                }
              />

              <RadioGroup
                row
                defaultValue="End"
                onChange={onAttendanceSelect}
                id="attendance-presenty-form"
              >
                <FormControlLabel
                  id="attendance-present"
                  value={ATTENDANCE.PRESENT}
                  control={
                    <Radio
                      color="primary"
                      checked={
                        state.markRequest.attendance === ATTENDANCE.PRESENT
                      }
                       disabled={state.markRequest.attendance === ATTENDANCE.ONLEAVE}
                    />
                  }
                  label="Present"
                />

                <FormControlLabel
                  id="attendance-on-leave"
                  value={ATTENDANCE.ONLEAVE}
                  disabled={
                    localDateFormatConverter2(selectedAttendanceDate) ===
                    localDateFormatConverter2(new Date())
                  }
                  control={
                    <Radio
                      color="primary"
                      checked={
                        state.markRequest.attendance === ATTENDANCE.ONLEAVE
                      }
                    />
                  }
                  label="On Leave"
                />
                
              </RadioGroup>
              <FormHelperText>{fieldError.attendance}</FormHelperText>
            </FormControl>
          </Grid>
          {state.markRequest.attendance === ATTENDANCE.PRESENT && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FormControl>
                <FormGroup
                  defaultValue="End"
                  onChange={(e) => {
                    inTime(e);
                    outTime(e);
                  }}
                  id="attendance-is-form"
                >
                  <FormControlLabel
                    id="attendance-in-time"
                    value={Object.freeze(currentdate)}
                    control={
                      <Checkbox
                        color="primary"
                        //checked={state.markRequest.inTime}
                        checked={inTime}
                        onChange={inTime}
                        disabled={state.markRequest.outTime}
                      />
                    }
                    label={
                      <div>
                        <div>
                          <b>In Time:</b> {formatTime(state.markRequest.inTime)}
                        </div>
                      </div>
                    }
                  />

                  <FormControlLabel
                    id="attendance-out-time"
                    value={"outTime"}
                    control={
                      <Checkbox
                        color="primary"
                        checked={state.markRequest.outTime}
                        disabled={state.markRequest.outTime}
                      />
                    }
                    label={
                      <div>
                        <div>
                          <b>Out Time:</b>{" "}
                          {formatTime(state.markRequest.outTime)}
                        </div>
                      </div>
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          )}

          {state.markRequest.attendance === ATTENDANCE.ONLEAVE && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FormControl>
                <FormGroup
                  defaultValue="End"
                  onChange={onHandleFrom}
                  id="attendance-is-form"
                >
                  <FormControlLabel
                    id="attendance-on-leave"
                    value={"onLeave"}
                    control={
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="flex-end"
                        item
                        xl={12}
                        lg={8}
                        md={6}
                        sm={12}
                        xs={12}
                      >
                        <Grid item>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <RangePicker
                              disabledDate={(current) => {
                                return moment().add(-1, "days") >= current;
                              }}
                              // size="middle"
                              style={{ marginBottom: 20 }}
                              getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode;
                              }}
                              popupStyle={{
                                position: "fixed",
                              }}
                              format={dateFormat}
                              disablePast
                              onChange={handleOnChange}
                            />
                            &nbsp;
                            {isOnLeaveSelected && (
                              <TextField
                                placeholder="Enter Leave Reason"
                                variant="outlined"
                                label="Reason"
                                size="medium"
                                multiline
                                fullWidth
                                onChange={(e) => {
                                  const { value } = e.target;

                                  setMarkLeaveRequest((prev) => ({
                                    ...prev,
                                    leaveReason: value,
                                  }));
                                }}
                                value={markLeaveRequest.leaveReason}
                                error={markLeaveRequest.leaveReason === ""}
                                helperText={"Enter Leave Reason"}
                              />
                            )}
                            <Typography variant="body2">
                              {props.reason.map((info, index) => {
                                const formattedDate = dateFormatConverter3(
                                  new Date(info.attendanceDate)
                                );
                                if (
                                  formattedDate ===
                                  dateFormatConverter3(selectedAttendanceDate)
                                ) {
                                  return (
                                    <span key={index}>
                                      <b>Reason:</b> {info.leaveReason}
                                    </span>
                                  );
                                }
                                return null;
                              })}
                            </Typography>
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions id="attendance-modal-action">
        <Button
          size="small"
          variant="contained"
          color="primary"
          id="attendance-cancel-button"
          onClick={onCancelClick}
        >
          {"Cancel"}
        </Button>

        <Button
          size="small"
          variant="contained"
          color="primary"
          id="attendance-save-button"
          onClick={onSaveClick}
        >
          {"Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userReducer.userDetails,
  };
};

const mapDispatchToProps = {
  showAlertModal,
  hideAlertModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Userattendance);
