import axios from 'axios';


const ATTENDANCE_URL = '/api/attendance'; 

// Mark employee in-time attendance
export const markAttendance = (markRequest) => {
    const options = {
        method: 'POST',
        url: `${ATTENDANCE_URL}/markpresent/IN`,
        data: markRequest
    }
    console.log('api',options)
    return axios(options)
   
}


// Mark employee out-time attendance
export const outTimeAttendance = (markRequest) => {
    const options = {
        method: 'PUT',
        url: `${ATTENDANCE_URL}/markpresent/OUT`,
        data: markRequest
    }
    console.log('api',markRequest)
    return axios(options)
   
}

//on-leave attendance
export const onLeaveAttendance = (markRequest) => {
    const options = {
        method: 'POST',
        url: `${ATTENDANCE_URL}/leave`,
        data: markRequest
    }
    console.log('api',markRequest)
    return axios(options)
   
}


// User attendance by month 
export const getAttendanceByMonth = (attendanceRequest) => {
    // console.log("arish@@@@@",attendanceRequest)
    const options = {
        method: 'GET',
        url: `${ATTENDANCE_URL}/find-by-month`,
        params: attendanceRequest
    }
    
    return axios(options)
   
}

// All users attendance by month 
export const findAttendance = (filterRequest) => {
    const options = {
        method: 'GET',
        url: `${ATTENDANCE_URL}/find-all`,
        params: filterRequest
    }
    
    return axios(options)
   
}


export const findByDate = (date, userName) => {
    const options = {
      method: "GET",
      url: `${ATTENDANCE_URL}/find/${userName}/${date}`,
    };
    return axios(options);
  };


// Update team attendance(manager)
export const updateAttendance = (updateRequest) => {
  const options = {
    method: 'PUT',
    url: `${ATTENDANCE_URL}/by-manager`,
    data: updateRequest
  };
  return axios(options)
};

// create team attendance(manager)
export const markManagerAttendance = (markRequest) => {
    const options = {
        method: 'POST',
        url: `${ATTENDANCE_URL}`,
        data: markRequest
    }
    console.log('api',options)
    return axios(options)
   
}
// cancel leave (manager)
export const cancelLeave = (cancelRequest) => {
    const options = {
        method: 'DELETE',
        url: `${ATTENDANCE_URL}/cancel-leave`,
        data: cancelRequest
    }
    return axios(options)
   
}

//download team attendance
export const downloadAttendance = (month) => {
    const options = {
        method: 'GET',
        url: `${ATTENDANCE_URL}/download`,
        params: month,
        responseType: 'blob'
    }
    
    return axios(options)
   
}




