import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Buffer } from "buffer";
import queryString from "querystring";
import { setJWTToken } from "../../utils/axios-config";
import { SECURIY_CONFIG } from "../../utils/constants";
import { login } from "../../store/actions/user-action";
import { generateCodeChallenge, generateCodeVerifier } from "../../utils/pkce";
import { useDispatch } from "react-redux";
import jwt from "jwt-decode";
import axios from "axios";


const authServerUrl = process.env.REACT_APP_AUTH_SERVER_URL;
const geooneFrontendUrl = process.env.REACT_APP_GEOMITRA_FRONTEND_URL;

const Redirect = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = queryString.parse(search);
  const dispatch = useDispatch();

  axios.interceptors.request.use(
    (config) => {
      config.headers = {
        ...config.headers,
      };
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      const token = response.data;
      console.log("Token ", token);
      if (token?.access_token) {
        console.log("Token ID : ", token.access_token);
        setJWTToken(token.access_token);
        localStorage.setItem("refreshToken", token.refresh_token);
        const username = jwt(token.access_token);
        dispatch(
          login({
            username: username.sub,
            token: token.access_token,
            roles: username.authorities,
          })
        );
        history.push("/workspace");
      }
      return response;
    },
    (error) => {
      //      if (error.response && error.response.status === 403) {
      //   // Redirect to the 403 page
      //   history.push("/403");
      // }
      if (error.response && error.response.status === 401) {
        // Redirect to the login page for unauthorized access
        history.push("/redirect");
      }
      console.log(error);
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (searchParams["?code"]) {
      const code = searchParams["?code"];
      const client = "client";
      const secret = "secret";
      const headers = {
        "Content-type": "application/json",
        Authorization: `Basic ${Buffer.from(`${client}:${secret}`).toString(
          "base64"
        )}`,
      };

      const verifier = generateCodeVerifier();

      const url = `${authServerUrl}/oauth2/token?client_id=${SECURIY_CONFIG.CLIENT_ID}&redirect_uri=${geooneFrontendUrl}/authorized&grant_type=${SECURIY_CONFIG.GRANT_TYPE}&code=${code}&code_verifier=${verifier}`;

      axios
        .post(
          url,
          {},
          {
            headers,
          }
        )
        .catch((err) => {
          console.log(err);
        });
    }
  }, [searchParams["?code"]]);

  useEffect(() => {
    if (!searchParams["?code"]) {
      const codeChallenge = generateCodeChallenge();
      const link = `${authServerUrl}/oauth2/authorize?response_type=${SECURIY_CONFIG.RESPONSE_TYPE}&client_id=${SECURIY_CONFIG.CLIENT_ID}&scope=${SECURIY_CONFIG.SCOPE}&redirect_uri=${geooneFrontendUrl}/authorized&code_challenge=${codeChallenge}&code_challenge_method=S256`;

      window.location.href = link;
    }
  }, [searchParams["?code"]]);
  return <p>Redirecting ...</p>;
};

export default Redirect;
