/**
 * @author Aman Harde
 * 
 */

import { FC } from 'react';
import { Container } from '@material-ui/core';
import TabSection from './tab-section';


const WorkSpace: FC = () => {


    return (
        <Container maxWidth="xl" id='workspace-wrapper' disableGutters>

            <TabSection />

        </Container>
    );

}



export default WorkSpace;
