import { makeStyles } from "@material-ui/core";
import jwt from 'jwt-decode';
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import theme from "../assets/theme/theme";
import { login } from "../store/actions/user-action";
import AuthenticatedRoutes from "../utils/authenticated-routes";
import { setJWTToken } from "../utils/axios-config";
import { SECURIY_CONFIG } from "../utils/constants";
import Redirect from "../view/login/re-direct";
import WorkSpace from "../view/workspace/workspace";


const authServerUrl = process.env.REACT_APP_AUTH_SERVER_URL;

const useStyles = makeStyles({
    root: {
        marginTop: theme.spacing(6),
        flexGrow: 1
    }
});

const Body: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        if (localStorage.getItem('refreshToken')) {
            const code = localStorage.getItem('refreshToken');
            const client = 'client';
            const secret = 'secret';
            const headers = new Headers();
            headers.append('Content-type', 'application/json');
            headers.append('Authorization', `Basic ${Buffer.from(`${client}:${secret}`).toString('base64')}`);

            const url = `${authServerUrl}/oauth2/token?client_id=${SECURIY_CONFIG.CLIENT_ID}&scope=${SECURIY_CONFIG.SCOPE}&grant_type=${SECURIY_CONFIG.REFRESH_TOKEN}&refresh_token=${code}`;

            fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers
            }).then(async (response) => {
                const token = await response.json();
                if (token?.access_token) {
                    setJWTToken(token.access_token);
                    const username: { sub: string, authorities: string[] } = await jwt(token.access_token);
                    dispatch(login({ username: username.sub, token: token.access_token, roles: username.authorities }));
                    console.log("roles",username.authorities);
                    history.push('/workspace');
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [])
    return (
        <div id='body-wrapper' className={classes.root}>
            <Switch>
                <Route path="/redirect" component={Redirect} />
                {/* <Route path="/403"  component={Page403} /> */}
                <AuthenticatedRoutes exact path="/workspace" component={WorkSpace} />
                <Route path="/authorized" render={() => <Redirect />} />
            </Switch>
        </div>
    )
}

export default Body;
