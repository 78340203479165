/**
 * This is create label form
 * @author Aman Harde
 * @since 1.0
 */

import { IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { AxiosResponse } from "axios";
import { FC, memo } from "react";
import { deleteLabel } from "../../apis/label-api";
import theme from "../../assets/theme/theme";
import TablePaginationActions from "../../components/drawer/table-pagination/table-paginationa-actions";
import { PageRequest } from "../../interface/common-interface";
import { LabelInfo } from "../../interface/label-interface";

interface LabelTableProps {
    waitForLabels: boolean,
    labels: LabelInfo[],
    totalElements: number,
    pageRequest: PageRequest,
    selectedLabel?: string,
    handlePagination: (pageRequest: PageRequest, event: any) => any,
    onActionClick: (action: string, version: string, event: any) => void,
    _fetchLabels: (event: any) => void
}

const useStyles = makeStyles({
    skeleton: {
        padding: theme.spacing(3)
    }
});

const LabelTable: FC<LabelTableProps> = (props) => {

    const classes = useStyles();

    const onActionClick = (action: string, labelNumber: string) => (event: any) => {

        switch (action) {
            case 'EDIT':
                props.onActionClick(action, labelNumber, event);
                break;

            case 'DELETE':
                if (window.confirm(`Do you want to delete label ${labelNumber}?`)) {
                    onDelete(action, labelNumber, event);
                    break;
                }
        }
    }

    const onDelete = (action: string, labelNumber: string, event: any) => {
        deleteLabel(labelNumber).then((res: AxiosResponse<any>) => {
            props._fetchLabels(event);
        }).catch((err: any) => {

        });
    }

    const handleChangePage = (event: any, page: number) => {
        props.handlePagination({ ...props.pageRequest, page }, event);
    }

    const handleChangeRowsPerPage = (event: any) => {
        props.handlePagination({ ...props.pageRequest, size: parseInt(event.target.value, 10) }, event);
    }

    return (
        <TableContainer component={Paper}>
        <div style={{ maxHeight: "700px", overflowY: "scroll" }}>
            <Table>
           < TableHead style={{ position: "sticky", top: 0, zIndex: 1,}} >
                    <TableRow>
                        <TableCell>Number</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell align='center'>{`Action`}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.waitForLabels ?
                            Array(5).fill(5).map((skeleton, index) => (
                                <TableRow key={index}>
                                    <TableCell colSpan={4} align='center'>
                                        <Skeleton
                                            variant="rect"
                                            width="95%" height='10%'
                                            animation='wave'
                                            className={classes.skeleton}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                            :
                            props.labels.length !== 0 ?
                                props.labels.map((info, index) => (
                                    <TableRow key={index} selected={info.labelNumber === props.selectedLabel}>
                                        <TableCell>{info.labelNumber}</TableCell>
                                        <TableCell>{info.name}</TableCell>
                                        <TableCell>{info.value}</TableCell>

                                        <TableCell align='center'>
                                            <IconButton size='small' color='primary' title='Edit Label' onClick={onActionClick('EDIT', info.labelNumber)}>
                                                <span className="material-icons">edit</span>
                                            </IconButton>
                                            <IconButton size='small' color='secondary' title='Delete Label' onClick={onActionClick('DELETE', info.labelNumber)}>
                                                <span className="material-icons">delete</span>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                                :
                                <TableRow>
                                    <TableCell colSpan={3} align="center">
                                        <Typography variant="h5"> Data Not Available </Typography>
                                    </TableCell>
                                </TableRow>
                    }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            colSpan={4}
                            count={props.totalElements}
                            rowsPerPage={props.pageRequest.size}
                            page={props.pageRequest.page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
            </div>
        </TableContainer >
    )

}

export default memo(LabelTable);