
import { Button, CircularProgress, Grid, InputAdornment, makeStyles, MenuItem, TextField, Typography } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { FC, Fragment, useEffect, useState } from "react";
import { editLabel, getLabel } from "../../apis/label-api";
import { checkFieldErrors } from "../../utils/field-error-checker";


const style = makeStyles({
    formWrapper: {
        marginTop: 0,
        margin: 'auto'
    }
});

interface LabelEditProps {
    readonly labelNumber: string,
    readonly _fetchLabels: (event: any) => void
}

const LabelEdit: FC<LabelEditProps> = (props) => {
    const classes = style();

    const [state, setState] = useState({
        formData: {
            name: '',
            value: ''
        },
        fieldError: {
            name: '',
            value: ''
        },
        wait: {
            forSubmit: false
        },
        fetchLabelError: "",
        errorMessage: ''
    });


    const resetState = (event: any) => {
        setState({
            formData: {
                name: '',
                value: ''
            },
            fieldError: {
                name: '',
                value: ''
            },
            wait: {
                forSubmit: false
            },
            fetchLabelError: '',
            errorMessage: ''
        });
    }

    useEffect(() => {
        getLabelInfo(props.labelNumber);
    }, [props.labelNumber]);

    const getLabelInfo = (labelNumber: string) => {
        getLabel(labelNumber).then((res: AxiosResponse<any>) => {
            setState({
                ...state,
                formData: res.data
            });
        }).catch((err: any) => {
            setState({
                ...state,
                fetchLabelError: 'Error while getting label!'
            });
        });
    }

    const onInputChange = (field: string) => (event: any) => {
        setState({
            ...state,
            formData: {
                ...state.formData,
                [field]: event.target.value
            },
            fieldError: {
                ...state.fieldError,
                [field]: ''
            },
            errorMessage: ''
        });
    }

    const onButtonClick = (value: string) => (event: any) => {

        switch (value) {
            case 'reset':
                resetState(event);
                break;

            case 'save':
                submitForm(event);
                break;

            default:
                break;
        }
    }

    const validateFields = (): boolean => {
        let fieldError = checkFieldErrors(state.fieldError, state.formData);

        setState({
            ...state,
            fieldError: fieldError.errors
        });

        return fieldError.haError;
    }

    const submitForm = (event: any) => {

        if (!validateFields()) {
            setState({
                ...state,
                wait: {
                    ...state.wait,
                    forSubmit: true
                },
            });

            editLabel(props.labelNumber, state.formData).then((res: AxiosResponse<any>) => {
                props._fetchLabels(event);
                setState({
                    ...state,
                    wait: {
                        ...state.wait,
                        forSubmit: false
                    },
                    errorMessage: ''
                });
            }).catch((err: any) => {
                setState({
                    ...state,
                    wait: {
                        ...state.wait,
                        forSubmit: false
                    },
                    errorMessage: 'Error while updating label.Please try again.'
                });
            });
        }
    }

    return (
        <Grid container spacing={3} item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.formWrapper}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h5">{`Edit Label ${props.labelNumber}`}</Typography>
            </Grid>
            {
                state.fetchLabelError === '' ?
                    <Fragment>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <TextField
                                variant='outlined'
                                size='small'
                                fullWidth
                                label='Label Name'
                                id='edit-label-name'
                                select
                                required
                                value={state.formData.name}
                                onChange={onInputChange('name')}
                                error={state.fieldError.name !== ''}
                                helperText={state.fieldError.name}
                                disabled={state.wait.forSubmit}
                            >
                                <MenuItem value={'Project'}>Project</MenuItem>
                                <MenuItem value={'Module'}>Module</MenuItem>
                                <MenuItem value={'Process'}>Process</MenuItem>
                                <MenuItem value={'Issue Type'}>Issue Type</MenuItem>
                                <MenuItem value={'Technology'}>Technology</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <TextField
                                variant='outlined'
                                size='small'
                                fullWidth
                                required
                                label='Label Value'
                                placeholder='Insert label value'
                                id='edit-label-value'
                                value={state.formData.value}
                                onChange={onInputChange('value')}
                                error={state.fieldError.value !== ''}
                                helperText={state.fieldError.value}
                                disabled={state.wait.forSubmit}
                            />
                        </Grid>

                        <Grid container spacing={1} item xl={12} lg={12} md={12} sm={12} xs={12} direction='row-reverse' alignContent='flex-end'>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    size='small'
                                    color='primary'
                                    id='edit-label-button'
                                    onClick={onButtonClick('save')}
                                    disabled={state.wait.forSubmit}
                                    endIcon={state.wait.forSubmit && <CircularProgress color='primary' size={20} />}
                                >
                                    {'Save Label'}
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    variant="contained"
                                    color='primary'
                                    size='small'
                                    id='reset-label-button'
                                    onClick={onButtonClick('reset')}
                                    disabled={state.wait.forSubmit}
                                >
                                    {'Reset'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Fragment>
                    : <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="subtitle2" color='secondary'>{state.fetchLabelError}</Typography>
                    </Grid>
            }
        </Grid>
    )
}



export default LabelEdit;