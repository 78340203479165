/**
 * This is funtion is to download file resource
 * @param {response} response
 */


function downloadFile(response, name) {
    const blob = new Blob([response.data],  { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    if (response.headers["filename"]) {
      link.setAttribute('download', response.headers["filename"]);   
    }else {
        link.setAttribute('download', `${name}.xlsx`); 
    }
    
    document.body.appendChild(link);
    link.click();
}


export default downloadFile;