import {
  Button,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { filterData } from "../../apis/user-api";
import searchRequestConvertor from "../../components/search-bar/searchRequestConvertor";
import CrudOperationTable from "../auth-user/auth-user-table";
import { CrudeOperationCreateDrawer, CrudeOperationEdit } from "../auth-user/auth-user-drawer";
import SearchBar from "../../components/search-bar";
const style = makeStyles({
  root: {
    display: "contents",
  },
  milestoneContainer: {
    margin: "auto",
  },
  milestoneTable: {
    display: "block",
  },
  createMilestone: {
    flex: "auto",
  },
  shiftTableLeft: {
        marginRight: 350
    },
  shiftTableRight: {
        marginRight: '0px',
    }
});

const CrudOperation = () => {
  const classes = style();

  const [state, setState] = useState({
    authUserData: [],
    waitForMileStones: true,
    errorMessage: "",
    pageRequest: {
      page: 0,
      size: 20,
    },
    totalElements: 0,
  });
  const [appliedFilters, setAppliedFilters] = useState();
  const [action, setAction] = useState("");
  const [authUserInfo, setAuthUserInfo] = useState('');
  
  const fetchAllData = (filterRequest) => {
    return new Promise((resolve, reject) => {
      filterData(filterRequest)
        .then((res) => {
          setState({
            ...state,
            authUserData: res.data,
            totalElements: res.data.totalElements,
            waitForMileStones: false,
          });
          resolve(res.status);
        })
        .catch((err) => {
          setState({
            ...state,
            waitForMileStones: false,
            errorMessage: "Error while getting milestones!",
          });
          reject();
        });
    });
  };

  const onFilterChange = (appliedFilters, pageRequest) => {
    setAppliedFilters(appliedFilters);
    const filterRequest = searchRequestConvertor(appliedFilters, pageRequest);

    return new Promise((resolve, reject) => {
      resolve(fetchAllData(filterRequest));
      reject();
    });
  };

  const onActionClick = (action, userName) => {
    setAction(action);
    setAuthUserInfo(userName);
  };

  const toggleDrawer = () => {
    setAction("");
  };

  const fetchAuthUserData = (event) => {
    onFilterChange(appliedFilters, state.pageRequest);
  };

  const fetchSavedFilter = () => {

  };

  return (
    <Container id="milestone-wrapper" maxWidth="xl">
      <Grid
        container
        spacing={3}
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={classes.root}
      >
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid item xl={10} lg={8} md={8} sm={6} xs={12}>
            <Typography variant="h4">{"Auth User"}</Typography>
          </Grid>
          <Grid
            container
            spacing={1}
            item
            xl={2}
            lg={4}
            md={4}
            sm={6}
            xs={12}
            direction="row-reverse"
            alignContent="flex-end"
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<span className="material-icons">add</span>}
                size="small"
                id="create-milestone-button"
                onClick={(event) => onActionClick("CREATE", null, null, event)}
              >
                {"Add Auth Users"}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Divider />
        
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} id='dashboard-search-bar-wrapper'>
                    <SearchBar
                        keys={[]}
                        suggestions={[]}
                        onFilterChange={(appliedFilters, pageRequest) => new Promise((resolve, reject) => {
                            resolve(onFilterChange(appliedFilters, pageRequest));
                            reject();
                        })}
                        fetchSavedFilter={fetchSavedFilter}
                        onSuggestionChange={()=>{}}
                    />
        </Grid>
        
        <Fragment>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.createMilestone}
          >
            <div className={action !== '' ? classes.shiftTableLeft : classes.shiftTableRight}>
            <CrudOperationTable
              authUserData={state.authUserData}
              pageRequest={state.pageRequest}
              totalElements={state.totalElements}
              // handlePagination={handlePagination}
              waitForMileStones={state.waitForMileStones}
              onActionClick={onActionClick}
              _fetchAuthUserData={fetchAuthUserData}
              />
            </div>
          </Grid>
          <div>
            {action === "CREATE" && (
              <CrudeOperationCreateDrawer
                drawerButton={action}
                closeDialog={toggleDrawer}
                _fetchAuthUserData={fetchAuthUserData}
              />
            )}
            {action === 'EDIT' && (
              <CrudeOperationEdit
                drawerButton={action}
                closeDialog={toggleDrawer}
                _fetchAuthUserData={fetchAuthUserData}
                authUserInfo={authUserInfo}
              />
             )}
          </div>
        </Fragment>
      </Grid>
    </Container>
  );
};

export default CrudOperation;
