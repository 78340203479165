import { Button, Container, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { FC, Fragment, useState } from "react";
import { fetchLabels } from "../../apis/label-api";
import { filterMilestones } from "../../apis/milestone-api";
import SearchBar from "../../components/search-bar";
import { AppliedFilter, FilterOption, PageRequest, Suggestion, SuggestionValues } from "../../components/search-bar/intefaces";
import searchRequestConvertor from "../../components/search-bar/searchRequestConvertor";
import { LabelInfo } from "../../interface/label-interface";
import { MilestoneRef } from "../../interface/milestone-interface";
import { AuthUser } from "../../interface/user-interface";
import { fetchFilter } from "../../store/actions/ui-actions";
import { EditMilestone, CreateMilestone } from "../milestone/create-milestone-drawer";
import MilestoneTable from "../milestone/milestone-table";
const style = makeStyles({
    root: {
        display: 'contents'
    },
    milestoneContainer: {
        margin: 'auto'
    },
    milestoneTable: {
        display: 'block'
    },
    createMilestone: {
        flex: "auto"
    },
    shiftTableLeft: {
        marginRight: 350
    },
    shiftTableRight: {
        marginRight: '0px',
    }
});

const filterOptions: FilterOption[] = [
    { label: "Project Reference", value: "projectRef", type: "suggestion", suggestionType: 'dynamic' },
    { label: "Status", value: "status", type: "suggestion", suggestionType: 'static' }
];

const status: Suggestion = {
    values: [{ displayMember: 'Open', valueMember: { status: 'OPEN' } },
    { displayMember: 'Closed', valueMember: { status: 'CLOSED' } }],
    key: filterOptions[1].label,
    ref: filterOptions[1].value
};

const projectReference: Suggestion = {
    values: [],
    key: filterOptions[0].label,
    ref: filterOptions[0].value

};

const Milestone: FC = () => {

    const classes = style();

    const [state, setState] = useState({
        milestones: [],
        waitForMileStones: true,
        errorMessage: '',
        pageRequest: {
            page: 0,
            size: 20
        },
        totalElements: 0,
    });
    const [appliedFilters, setAppliedFilters] = useState([] as any)
    const [action, setAction] = useState('');
    const [milestoneInfo, setMilestoneInfo] = useState({} as any);

    const fetchAllMilestones = (filterRequest: {}) => {
        return new Promise((resolve, reject) => {
            filterMilestones(filterRequest).then((res: AxiosResponse<any>) => {
                setState({
                    ...state,
                    milestones: res.data.content,
                    pageRequest: {
                        page: res.data.number,
                        size: res.data.size
                    },
                    totalElements: res.data.totalElements,
                    waitForMileStones: false
                });
                resolve(res.status);
            }).catch((err: any) => {
                setState({
                    ...state,
                    waitForMileStones: false,
                    errorMessage: 'Error while getting milestones!'
                });
                reject()
            });
        });
    }

    const onFilterChange = (appliedFilters: any, pageRequest: any) => {
        setAppliedFilters(appliedFilters)
        const filterRequest = searchRequestConvertor(appliedFilters, pageRequest);

        return new Promise((resolve, reject) => {
            resolve(fetchAllMilestones(filterRequest));
            reject();
        });
    }

    const onActionClick = (action: string, milestoneNumber: string | null, version: string | null, event: any) => {
        setAction(action);
        setMilestoneInfo({
            milestoneNumber,
            version
        });
    }

    const toggleDrawer = () => {
        setAction('');
    }
    const handlePagination = (pageRequest: PageRequest, event: any) => {
        onFilterChange(appliedFilters, { page: pageRequest.page + 1, size: pageRequest.size });
    }
    const onSuggestionChange = (selectedKey: FilterOption, value: string) => {
        switch (selectedKey.value) {

            case 'projectRef':
                return new Promise((resolve, reject) => {
                    fetchLabels({ name: 'Project', value: value }).then((res: AxiosResponse<any>) => {
                        let values: SuggestionValues[] = [];
                        res.data.map((_label: LabelInfo) => {
                            values.push({ displayMember: _label.value, valueMember: { label: `${_label.value}` } })
                        });

                        resolve(values);
                    });
                });
        }
    }
    const fetchMilestoneData = (event: any) => {
        onFilterChange(appliedFilters, state.pageRequest);
    }
    const fetchSavedFilter = () => {

    }
    return (
        <Container id='milestone-wrapper' maxWidth='xl'>
            <Grid container spacing={3} item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.root}>
                <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item xl={10} lg={8} md={8} sm={6} xs={12}>
                        <Typography variant="h4">{"Milestone"}</Typography>
                    </Grid>
                    <Grid container spacing={1} item xl={2} lg={4} md={4} sm={6} xs={12} direction='row-reverse' alignContent='flex-end'>
                        <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                startIcon={<span className="material-icons">add</span>}
                                size='small'
                                id='create-milestone-button'
                                onClick={(event: any) => onActionClick('CREATE', null, null, event)}
                            >
                                {'Create Milestone'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider />

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} id='dashboard-search-bar-wrapper'>
                    <SearchBar
                        keys={filterOptions}
                        suggestions={[projectReference, status]}
                        onFilterChange={(appliedFilters: any, pageRequest: any) => new Promise((resolve, reject) => {
                            resolve(onFilterChange(appliedFilters, pageRequest));
                            reject();
                        })}
                        fetchSavedFilter={fetchSavedFilter}
                        onSuggestionChange={onSuggestionChange}
                    />
                </Grid>

                <Fragment>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.createMilestone}>
                        <div className={action !== '' ? classes.shiftTableLeft : classes.shiftTableRight}>
                            <MilestoneTable
                                milestones={state.milestones}
                                pageRequest={state.pageRequest}
                                totalElements={state.totalElements}
                                handlePagination={handlePagination}
                                waitForMileStones={state.waitForMileStones}
                                onActionClick={onActionClick}
                                _fetchMilestones={fetchMilestoneData}
                            />
                        </div>
                    </Grid>
                    <div>
                        {action === 'CREATE' && <CreateMilestone
                            drawerButton={action}
                            closeDialog={toggleDrawer}
                            _fetchMilestones={fetchMilestoneData}
                        />
                        }
                        {action === 'EDIT' && <EditMilestone
                            drawerButton={action}
                            closeDialog={toggleDrawer}
                            _fetchMilestones={fetchMilestoneData}
                            milestoneInfo={milestoneInfo}
                        />
                        }
                    </div>
                </Fragment>

            </Grid>
        </Container>
    )

}

export default Milestone;