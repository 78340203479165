import {
  Box,
  Button,
  CircularProgress,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import ClearIcon from "@material-ui/icons/Clear";
import LinkIcon from "@material-ui/icons/Link";
import { Divider } from "antd";
import { AxiosResponse } from "axios";
import { FC, useEffect, useState } from "react";
import { UPLOAD_STATUS } from ".";
import { deleteIssueLinks, SupportLink } from "../../apis/issue-api";
import theme from "../../assets/theme/theme";


interface FileUploadHandlerProps {
  onChange: (event: any) => void;
  disableFileUpload?: boolean;
  onCancelUpload: (event: any) => void;
  errorMessage?: string;
  status: UPLOAD_STATUS;
  progress: number;
  files: any[];
  issueNumber: string;
  fetchIssue: () => void;
  li: any[];
}

const style = makeStyles({
  cancelUploadButtton: {
    background: theme.palette.error.main,
    "&:hover": {
      backgroundColor: colors.red[700],
    },
    color: theme.palette.common.white,
  },
  clearIcon: {
    color: theme.palette.error.main,
  },
  listItem: {
    padding: theme.spacing(1),
  },
  linkButton: {
    background: (props: { hasLinks: boolean }) => (props.hasLinks ? 'green' : theme.palette.primary.main),
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: (props: { hasLinks: boolean }) => (props.hasLinks ? colors.green[700] : theme.palette.primary.dark),
    },
  },
});

const FileUploadHandler: FC<FileUploadHandlerProps> = (props) => {
  const hasLinks = props.li.some(file => file.linkType === "EXTERNAL_LINK");
  const classes = style({ hasLinks });
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState("");
  const [links, setLinks] = useState<string[]>([]);
  const [externalFiles, setExternalFiles] = useState(props.li.filter(file => file.linkType === "EXTERNAL_LINK"));
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [buttonColor, setButtonColor] = useState(() => {
    return localStorage.getItem("buttonColor") || theme.palette.primary.main;
  });


  const savedLinks = props.li;

  useEffect(() => {
    setExternalFiles(props.li.filter(file => file.linkType === "EXTERNAL_LINK"));
  }, [props.li]);


  const onInputChange = (event: any) => {
    props.onChange(event);
  };

  const onButtonClick = (name: string) => (event: any) => {
    props.onCancelUpload(event);
  };

  const onCancelClick = () => {
    setOpen(false);
  };

  const handleLinkChange = (event: any) => {
    setLink(event.target.value);
  };

  const addLink = () => {
    const trimmedLink = link.trim();

    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;

    if (!trimmedLink || !urlPattern.test(trimmedLink)) {
      setErrorMessage("Please enter a valid link.");
      console.error("Invalid URL:", trimmedLink);
      return;
    }

    const issueNumber = props.issueNumber;
    const request = { issueNumber: issueNumber, link: trimmedLink };
    const newLinkName = `Link_${externalFiles.length + 1}`;
    // const newLink = { path: trimmedLink, linkType: "EXTERNAL_LINK", originalName: newLinkName };
    const newLinkId = Date.now();


    SupportLink(issueNumber, request)
      .then((response: { data: { link: string; }; }) => {
        //setLinks([response.data.link]);
        setLinks((prevLinks) => [...prevLinks, response.data.link]);
        setExternalFiles((prevFiles) => [...prevFiles, { path: trimmedLink, linkType: "EXTERNAL_LINK", originalName: newLinkName, id: newLinkId }]);
        setErrorMessage("");
        setLink("");
        const newColor = colors.green[500];
        setButtonColor(newColor);
        localStorage.setItem("buttonColor", newColor);
        props.fetchIssue();
      })
      .catch((error: any) => {
        console.log("error adding support link", error);
      });
  };

  const deleteLink = (linkToRemove: string) => {

    const userConfirmed = window.confirm(`Do you want to delete link ?`);
    if (!userConfirmed) {
      return;
    }
    const issueNumber = props.issueNumber;
    //const request = { path: linkToRemove };
    const request = { path: externalFiles.find(link => link.id === linkToRemove)?.path };

    deleteIssueLinks(issueNumber, request)
      .then((res: AxiosResponse<any>) => {
        setExternalFiles((prevFiles) =>
          prevFiles.filter((savedLink) => savedLink.id !== linkToRemove)
        );

        setLinks((prevLinks) =>
          prevLinks.filter((savedLink) => savedLink !== linkToRemove)
        );

        if (externalFiles.length === 1) {
          setButtonColor(theme.palette.primary.main);
          localStorage.setItem("buttonColor", theme.palette.primary.main);
        }

        props.fetchIssue();
      })
      .catch((err: any) => {
        console.log("error removing support link", err);
      });
  };

  const onClearIconClick = (linkToRemove: string) => () => {
    deleteLink(linkToRemove);
  };


  return (
    <Grid container spacing={3} item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Grid item container spacing={2} xl={12} lg={12} md={12} sm={12} xs={12}>
        {props.status === UPLOAD_STATUS.IN_PROGRESS ? (
          <Grid item>
            <Button
              variant="contained"
              size="medium"
              className={classes.cancelUploadButtton}
              onClick={onButtonClick("cancelUpload")}
            >
              {"Cancel Uploading"}
            </Button>
          </Grid>
        ) : (
          <Grid item>
            <label htmlFor="upload-file">
              <input
                id="upload-file"
                name="upload-file"
                style={{ display: "none" }}
                type="file"
                multiple
                onChange={onInputChange}
                disabled={props.disableFileUpload}
              />
              <Button
                variant="contained"
                disabled={props.disableFileUpload}
                color="primary"
                component="span"
                startIcon={<span className="material-icons">upload_file</span>}
              >
                {`Select Files`}
              </Button>
              &nbsp;
              <Button
                variant="contained"
                // color="primary"
                style={{ backgroundColor: buttonColor, color: theme.palette.common.white }}
                onClick={() => setOpen(true)}
                startIcon={<LinkIcon />}
              >
                {`Link`}
              </Button>
              <Dialog
                open={open}
                maxWidth="sm"
                fullWidth={true}
                onClose={onCancelClick}
                disableBackdropClick={true}
              >
                <DialogTitle>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs="auto">
                      <AssignmentTurnedInIcon fontSize="large" />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="h6">{"Attach Links"} </Typography>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent dividers>
                  <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

                    </Grid>
                    <TextField
                      variant="outlined"
                      label="Attatch URL"
                      placeholder="Enter url"
                      size="small"
                      id="attatch-link"
                      fullWidth
                      onChange={handleLinkChange}
                      value={link}
                      InputProps={{
                        startAdornment: <LinkIcon />,
                      }}
                    
                    />
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

                  </Grid>
                  <Divider />
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <List dense disablePadding>
                      {externalFiles.length !== 0 ? (
                        externalFiles.map(
                          (savedLink, index) => (
                            <ListItem
                              key={index}
                              onClick={() => {
                                const baseURL = "https://";
                                const url = savedLink.path.startsWith("http")
                                  ? savedLink.path
                                  : `${baseURL}/${savedLink.path}`;
                                if (url && url.startsWith("http")) {
                                  window.open(url, "_blank");
                                } else {
                                  console.error("Invalid URL:", url);
                                }
                              }}
                              button
                              dense
                              disableGutters
                            >
                              <ListItemIcon>
                                <LinkIcon />
                              </ListItemIcon>
                              <ListItemText disableTypography>
                                <Typography variant="inherit" noWrap>
                                  {savedLink.originalName}
                                </Typography>
                              </ListItemText>
                              <ListItemSecondaryAction>
                                <IconButton
                                  size="small"
                                  onClick={onClearIconClick(savedLink.id)}
                                >
                                  <ClearIcon
                                    className={classes.clearIcon}
                                    fontSize="small"
                                  />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>

                          )
                        )
                      ) : (
                        <Typography variant="h6" align="center">
                          {"Support files not found"}
                        </Typography>
                      )}
                    </List>
                  </Grid>
                </DialogContent>


                <DialogActions id="attendance-modal-action">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    id="attendance-cancel-button"
                    onClick={onCancelClick}
                  >
                    {"Cancel"}
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    id="attendance-cancel-button"
                    onClick={addLink}

                  >
                    {"Save"}
                  </Button>
                </DialogActions>
              </Dialog>
            </label>
          </Grid>
        )}
        {props.status === UPLOAD_STATUS.CANCELLED && (
          <Grid item>
            <Button
              variant="outlined"
              size="medium"
              color="inherit"
              onClick={onButtonClick("cancelUpload")}
              startIcon={<span className="material-icons">refresh</span>}
            >
              {"Retry Uploading"}
            </Button>
          </Grid>
        )}
      </Grid>
      {props.progress !== 0 && (
        <Grid
          container
          spacing={2}
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          alignItems="center"
        >
          <Grid item>
            <Box position="relative" display="inline-flex">
              <CircularProgress
                variant="determinate"
                value={props.progress}
                thickness={4}
                size={50}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                >{`${Math.round(props.progress)}%`}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {props.files.length === 1
                ? `Uploading a file...`
                : `Uploading ${[props.files.length]} files...`}
            </Typography>
          </Grid>
        </Grid>
      )}
      {props.errorMessage && (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="subtitle1" color="secondary">
            {props.errorMessage}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};


export default FileUploadHandler;
