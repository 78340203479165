import { Container, Divider, Grid, makeStyles, Chip, Typography, List, ListItem, ListItemText, Paper, ListItemSecondaryAction, IconButton } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { FC, Fragment, useState } from "react";
import { findUserStatusLogInfo } from "../../apis/issue-api";
import { getUsersByPattern } from "../../apis/user-api";
import SearchBar from "../../components/search-bar";
import { FilterOption, PageRequest, Suggestion, SuggestionValues } from "../../components/search-bar/intefaces";
import searchRequestConvertor from "../../components/search-bar/searchRequestConvertor";
import { AuthUser } from "../../interface/user-interface";
import ViewIssueDiff from '../issue/modal/view-diff';
import { formatToDefaultWithTime } from "../../utils/date-formater";
import { useDispatch } from "react-redux";
import { showTab } from "../../store/actions/ui-actions";
import { Skeleton } from "@material-ui/lab";


const style = makeStyles({
    root: {
        display: 'contents'
    },
    milestoneContainer: {
        margin: 'auto'
    },
    milestoneTable: {
        display: 'block'
    },
    createMilestone: {
        flex: "auto"
    },
    shiftTableLeft: {
        marginRight: 350
    },
    shiftTableRight: {
        marginRight: '0px',
    },
    refClick: {
        cursor: 'pointer',
        "&:hover": {
            textDecoration: 'underline'
        }
    },
    skeleton: {
        padding: 100
    },
});

const filterOptions: FilterOption[] = [
    { label: "Assignee", value: "assignee", type: "suggestion", suggestionType: "dynamic" },
    { label: "Date Range", value: "dateRange", type: "date_range" },
   
    

];

const assignee: Suggestion = {
    values: [],
    key: filterOptions[0].label,
    ref: filterOptions[0].value

};

const dateRage: Suggestion = {
    values: [{ displayMember: filterOptions[1].label, valueMember: { startDate: new Date(), endDate: new Date() } }],
    key: filterOptions[1].label,
    ref: filterOptions[1].value
};



const UserStatusLog: FC = () => {

    const classes = style();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        userStatusLogInfo: [],
        wait: true,
        buttonValue: '',
        diff: '""',
        errorMessage: '',
        pageRequest: {
            page: 0,
            size: 20
        },
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
    });


    const jsonStyle = {
        propertyStyle: { color: 'black', fontWeight: 'bold', fontSize: 16 },
        style: { color: 'black', fontSize: 16 }
    }

    const [appliedFilters, setAppliedFilters] = useState([] as any)


    const handleClose = (event: any) => {
        setState({
            ...state,
            buttonValue: '',
        });
    }


    const fetchUserStatusLogInfo = (filterRequest: {}) => {
        console.log(filterRequest)

        return new Promise((resolve, reject) => {
            findUserStatusLogInfo(filterRequest).then((res: AxiosResponse<any>) => {
                setState({
                    ...state,
                    userStatusLogInfo: res.data.content,
                    pageRequest: {
                        page: res.data.number,
                        size: res.data.size
                    },
                    wait: false,
                    totalPages: res.data.totalPages,
                    totalElements: res.data.totalElements,
                    numberOfElements: res.data.numberOfElements,
                });

                resolve(res.status);
            }).catch((err: any) => {
                setState({
                    ...state,
                    wait: false,
                    errorMessage: 'Error while getting User Status Log!'
                });
                reject()
            });
        });
    }

    const onFilterChange = (appliedFilters: any, pageRequest: any) => {
        setAppliedFilters(appliedFilters)
        const filterRequest = searchRequestConvertor(appliedFilters, pageRequest);

        return new Promise((resolve, reject) => {
            resolve(fetchUserStatusLogInfo(filterRequest));
            reject();
        });
    }




    const onSuggestionChange = (selectedKey: FilterOption, value: string) => {
        switch (selectedKey.value) {

            case 'assignee':
                return new Promise<SuggestionValues[]>((resolve, reject) => {
                    getUsersByPattern({ authUser: value }).then((res: AxiosResponse<any>) => {
                        console.log("users", res)

                        let values: SuggestionValues[] = [];

                        res.data.map((user: AuthUser) => {
                            values.push({ displayMember: user.username, valueMember: { assignee: user.username } })
                        });
                        resolve(values);
                    });

                });


                case 'attendance':
                return alert("u clicked on attendance")
                    

                
        }
    }
    const fetchMilestoneData = (event: any) => {
        onFilterChange(appliedFilters, state.pageRequest);
    }
    const fetchSavedFilter = () => {

    }


    const onRefrenceClick = (label: any, value: any) => (event: any) => {
        switch (label) {
            case "issueNumber":
                dispatch(showTab("VIEW_ISSUE_INFO_TAB", { issueNumber: value }))
                break;

            case "view-diff":
                setState({
                    ...state,
                    buttonValue: label,
                    diff: value.diff
                });
                break;

            default:
                break;
        }
    }
    return (
        <Container id='user-log-wrapper' maxWidth='xl'>
            <Grid container spacing={3} item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.root}>
                <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item xl={10} lg={8} md={8} sm={6} xs={12}>
                        <Typography variant="h4">{"User Status Log"}</Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} id='dashboard-search-bar-wrapper'>
                    <SearchBar
                        keys={filterOptions}
                        suggestions={[assignee, dateRage ]}
                        onFilterChange={(appliedFilters: any, pageRequest: any) => new Promise((resolve, reject) => {
                            resolve(onFilterChange(appliedFilters, pageRequest));
                            reject();
                        })}
                        fetchSavedFilter={fetchSavedFilter}
                        onSuggestionChange={onSuggestionChange}
                        allowPagination
                        totalPages={state.totalPages}
                        showingResults={{ totalElements: state.totalElements, numberOfElements: state.numberOfElements }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    {
                        state.wait ?
                            Array(10).fill(undefined).map((skeleton, index) => (
                                <List component={Paper} key={index}>
                                    <ListItem >
                                        <Skeleton
                                            variant="rect"
                                            width="100%" height='10%'
                                            animation='wave'
                                            className={classes.skeleton}
                                        />
                                    </ListItem>
                                </List>
                            ))

                            : state.userStatusLogInfo != undefined && state.userStatusLogInfo.length !== 0 ?
                                <List component={Paper} id='user-status-list'>
                                    {
                                        state.userStatusLogInfo != undefined && state.userStatusLogInfo.map((info: any, index) => {
                                            return (
                                                <ListItem
                                                    key={index}
                                                    divider={index !== (state.userStatusLogInfo.length - 1)}
                                                    id={`log-${index}`}

                                                >
                                                    <ListItemText disableTypography>
                                                        <Grid container spacing={1} >
                                                            <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                                                                <Typography variant='h6' id={`log - ${index} - title`}>
                                                                    {`@`}{info.userName}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid container spacing={1} item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                <Typography variant='h6' id={`log - ${index} - title`}>
                                                                {'#'}
                                                                </Typography>
                                                                <Typography variant='h6' >
                                                                    <span onClick={onRefrenceClick("issueNumber", info.issueNumber)} className={classes.refClick} >    {info?.issueNumber}</span> </Typography>
                                                                <Typography variant='h6' id={`log - ${index} - title`}>
                                                                    {`  : ${info.activityInfo} on ${formatToDefaultWithTime(info?.time)} `}
                                                                </Typography>
                                                            </Grid>


                                                        </Grid>
                                                    </ListItemText>
                                                    <ListItemSecondaryAction >
                                                        <IconButton size='small' color='primary' title='View Change Log' onClick={onRefrenceClick('view-diff', info)}>
                                                            <span className="material-icons">search</span>
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )
                                        }
                                        )
                                    }
                                </List>
                                : <Grid item xl={12} lg={12} md={12} sm={12} xs={12} component={Paper}>
                                    <Typography variant='h6' align='center'>User Status Log Not found</Typography>
                                </Grid>

                    }
                </Grid>
                <ViewIssueDiff
                    buttonValue={state.buttonValue}
                    logInfo={state.diff}
                    handleClose={(event: any) => { handleClose(event) }}
                    jsonStyle={jsonStyle}

                />
            </Grid>
        </Container>
    )

}

export default UserStatusLog;